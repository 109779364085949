import { Collaborator, JsonValue } from "hassibot/services_v2/common/types";
import { isoDateReader, opt } from "hassibot/services_v2/common/reader";
import {
  CollaboratorChatSettings,
  CollaboratorTask,
} from "hassibot/services_v2/collaborator/types";

export const collaboratorReader = (json: JsonValue): Collaborator => ({
  birthday: opt(isoDateReader)(json.birthday),
  firstDay: opt(isoDateReader)(json.firstDay),
  firstName: json.firstName,
  lastName: json.lastName,
  mail: json.mail,
  dsitJob: json.dsitJob,
  deleted: Boolean(json.deleted),
  phones: (json.phones || []).map(p => ({
    type: p.type,
    value: p.value,
  })),
  pictureUrl: json.pictureUrl,
  place: json.place,
  agencyUuids: json.agencyUuids,
  roles: json.roles,
  googleId: json.googleId,
  featureFlags: json.featureFlags,
  microsoftId: json.microsoftId,
  dsitUuid: json.dsitUuid,
});

export const collaboratorsReader = (json: JsonValue): Collaborator[] =>
  json.map(collaboratorReader);

export const collaboratorTaskReader = (json: JsonValue): CollaboratorTask => ({
  uuid: json.uuid,
  type: json.type,
  linkedEntityId: json.linkedEntityId,
  collaboratorDsitUuid: json.collaboratorDsitUuid,
  status: json.status,
  agencyUuid: json.agencyUuid,
  content: json.content,
  createdAt: opt(isoDateReader)(json.createdAt),
  updatedAt: opt(isoDateReader)(json.updatedAt),
});

export const collaboratorTasksReader = (json: JsonValue): CollaboratorTask[] =>
  json.map(collaboratorTaskReader);

export const collaboratorChatSettingsReader = (json: JsonValue): CollaboratorChatSettings => ({
  collaboratorDsitUuid: json.collaboratorDsitUuid,
  subscribedAgencyUuids: json.subscribedAgencyUuids,
  isSilentModeOn: json.isSilentModeOn,
});
