import styled from "styled-components";

import { radius, shadow } from "styles/const";
import { color, spaces } from "emoreg/const";

export const Card = styled.div`
  background: #fff;
  border-radius: ${radius.lg};
  border: 0.5px solid ${color.grey.base};
  box-shadow: ${shadow.card.base};
  padding: ${spaces[24]} ${spaces[16]};
`;
