import styled from "styled-components";
import { css } from "styled-components";
import { rem } from "polished";
import { switchProp, ifProp } from "styled-tools";

import { radius, font } from "styles/const";
import { color, spaces } from "emoreg/const";

import IconLabelled from "./icon-labelled";
import { Icon, IconName } from "emoreg/atoms/Icon";
import { ChosenSize } from "./icon-labelled";
import { WrapperLoader } from "hassibot/component/documents-v2/organisms/template-add-document/styles";
import { InlineLoader } from "emoreg/atoms/Loader";

export const reset = css`
  appearance: none;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const generateButtonStyle = color => (isOutlined: boolean) => (isDisabled: boolean) => {
  return css`
    background: ${isOutlined ? "transparent" : color};
    color: ${isOutlined ? color : "#fff"};
    border: 1px solid ${isOutlined ? color : "#fff"};
    position: relative;
    align-items: center;
    svg {
      fill: ${isOutlined ? color : "#fff"};
    }

    ${!isDisabled &&
    css`
      &:hover {
        background: ${isOutlined ? color : "transparent"};
        color: ${isOutlined ? "#fff" : color};
        border: 1px solid ${isOutlined ? "#fff" : color};

        svg {
          fill: ${isOutlined ? "#fff" : color};
        }
      }
    `}
  `;
};

const secondaryStyles = {
  normal: css`
    background: #eeefef;
    color: ${color.grey.dark};
    border: 1px solid #eeefef;
    transition: 0.25s ease-in-out;
    svg {
      fill: ${color.grey.dark};
    }
  `,
  outlined: css`
    background: white;
    color: ${color.grey.dark};
    border: 1px solid ${color.grey.base};
    transition: 0.25s ease-in-out;
    svg {
      fill: ${color.grey.dark};
    }
  `,
  outlinedHover: css`
    background: #eeefef;
    color: ${color.grey.dark};
    border: 1px solid ${color.grey.base};
  `,
};

const selectedStyles = {
  normal: css`
    background: ${color.grey.base};
    color: #000;
    border: 1px solid ${color.grey.base};
    transition: 0.25s ease-in-out;
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.15);
    font-weight: ${font.weight.bold} svg {
      fill: ${color.grey.dark};
    }
  `,
  outlined: css`
    background: transparent;
    color: #000;
    border: 1px solid ${color.grey.base};
    transition: 0.25s ease-in-out;
    font-weight: ${font.weight.normal} svg {
      fill: ${color.grey.dark};
    }
    :hover {
      background: ${color.grey.base};
    }
  `,
  outlinedHover: css`
    background: transparent;
    color: #000;
    border: 1px solid ${color.grey.base};
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.15);
  `,
};

const typeStyle = props => ({
  primary: generateButtonStyle(color.blue.dark)(props.outlined)(props.disabled),
  highlight: generateButtonStyle(color.blue.dark)(props.outlined)(props.disabled),
  error: generateButtonStyle(color.red.base)(props.outlined)(props.disabled),
  selected: css`
    ${props.outlined ? selectedStyles.outlined : selectedStyles.normal};
    /* disable hover on device who doesn't support hover */
    @media (hover: hover) {
      &:hover {
        ${ifProp(
          "disabled",
          selectedStyles.normal,
          ifProp("outlined", selectedStyles.outlinedHover, selectedStyles.outlined)
        )};
      }
    }
  `,
  secondary: css`
    ${props.outlined ? secondaryStyles.outlined : secondaryStyles.normal};
    /* disable hover on device who doesn't support hover */
    @media (hover: hover) {
      &:hover {
        ${props =>
          props.disabled
            ? css`
                ${secondaryStyles.normal};
                ${ifProp("outlined", secondaryStyles.outlined)}
              `
            : css`
                ${ifProp("outlined", secondaryStyles.outlinedHover)}
              `}
      }
    }
  `,
});

const ButtonStyle = css`
  display: inline-flex;
  border: none;
  justify-content: center;
  font-weight: ${font.weight.bold};
  border-radius: ${radius.base};
  color: ${props => (props.color ? props.color : "#fff")};
  background: ${props => (props.background ? props.background : "none")};
  transition: 0.25s ease-in-out;
  ${ifProp("disabled", `cursor: not-allowed; opacity: 0.4;`, `cursor: pointer;`)}
  & * {
    ${ifProp("disabled", `cursor: not-allowed !important;`, `cursor: pointer;`)}
  }
  ${ifProp(
    { size: "xs" },
    `font-size: ${rem(10)}
`
  )};
  padding: ${switchProp("size", {
    xs: `${spaces[4]} ${spaces[8]}`,
    sm: `${spaces[8]} ${spaces[12]};`,
    md: `${spaces[12]} ${spaces[16]};`,
    lg: `${spaces[16]} ${spaces[24]};`,
  })};
`;
const ButtonStyleTypeProp = css`
  ${switchProp("type", props => typeStyle(props))};
`;
const ButtonWithoutTypeProp = css`
  ${switchProp("customType", props => typeStyle(props))};
`;

const FinalButton = styled(({ type, ...others }) => (
  <TagButton {...others} customType={type} type="button" />
))``;

const TagButton = styled.button`
  ${reset};
  ${ButtonStyle};
  ${ButtonWithoutTypeProp};
`;

const TagA = styled.a`
  text-decoration: none !important;
  ${reset};
  ${ButtonStyle};
  ${ButtonStyleTypeProp}
`;

const PrecomputeButton = styled.div``;

type Sizes = "xs" | "sm" | "md" | "lg";
export type ButtonProps = {
  onClick?: any;
  type?: "primary" | "secondary" | "highlight" | "error" | "disabled" | "selected";
  size?: Sizes | number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  // to rationalize
  icon?: { name: IconName; size?: keyof ChosenSize; color?: string };
  iconName?: IconName;
  color?: string;
  active?: boolean;
  href?: string;
  outlined?: boolean;
  disabled?: boolean;
  title?: string;
  isPending?: boolean;
  target?: string;
};

/**
 * @deprecated
 * @description please use src/emoreg/Button
 */
export const Button = ({
  type = "primary",
  size = "md",
  disabled = false,
  children,
  icon,
  iconName,
  color,
  onClick,
  active,
  href,
  title,
  outlined,
  style,
  isPending,
  target,
}: ButtonProps) => {
  const ButtonContent = () => {
    if (isPending) {
      return (
        <WrapperLoader>
          <InlineLoader />
        </WrapperLoader>
      );
    } else if (iconName) {
      return <Icon name={iconName} size={14} />;
    } else if (icon) {
      return <IconLabelled size={icon.size} name={icon.name} color={icon.color} label={children} />;
    } else {
      return children;
    }
  };

  return (
    <PrecomputeButton
      title={title}
      type={isPending ? "secondary" : type}
      size={size}
      disabled={isPending || disabled}
      color={color}
      onClick={onClick}
      active={active}
      href={href}
      outlined={outlined}
      as={href ? TagA : FinalButton}
      style={style}
      target={target}
    >
      {ButtonContent()}
    </PrecomputeButton>
  );
};
