import { DateTime } from "luxon";
import { RRule, Weekday } from "rrule";

export class StaffingPlan {
  constructor(public rrule: RRule, public endTime: string, public daysSpanned: number) {}

  public static everyDay = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
  public static mondaySaturday = StaffingPlan.everyDay.filter((d) => !d.equals(RRule.SU));
  public static mondayFriday = StaffingPlan.mondaySaturday.filter((d) => !d.equals(RRule.SA));

  public static isSame = (arr1: Weekday[] = [], arr2: Weekday[] = []): boolean =>
    arr1.length === arr2.length && arr1.every((e, i) => e.equals(arr2[i]));

  fromDate = (): DateTime => DateTime.fromJSDate(this.rrule.options.dtstart).setZone("utc");
  toDate = (): DateTime => {
    const matches = this.endTime.match(/(\d{1,2}):(\d{2})/) as RegExpMatchArray;
    return this.fromDate()
      .plus({ day: this.daysSpanned })
      .set({
        hour: parseInt(matches[1]),
        minute: parseInt(matches[2]),
      });
  };

  public static humanJoin = (arr: string[]) => {
    const recur = (arr: string[], acc: string): string => {
      const [head, ...tail] = arr;
      if (!head) {
        return acc;
      } else {
        const suffix = tail.length === 0 ? "" : tail.length === 1 ? " et " : ", ";
        return recur(tail, acc + head + suffix);
      }
    };
    return recur(arr, "les ");
  };

  static mapping = {
    [RRule.MO.toString()]: "lundi",
    [RRule.TU.toString()]: "mardi",
    [RRule.WE.toString()]: "mercredi",
    [RRule.TH.toString()]: "jeudi",
    [RRule.FR.toString()]: "vendredi",
    [RRule.SA.toString()]: "samedi",
    [RRule.SU.toString()]: "dimanche",
  };

  toHumanText = (): string => {
    const dateToText = `du ${this.fromDate().toFormat("cccc dd LLLL yyyy")}`;

    const [fromHoursText, toHoursText] = [this.fromDate, this.toDate].map((d) =>
      d().toFormat("HH:mm")
    );

    const toDateOffsetFormat = (offset: number): string | null => {
      switch (true) {
        case offset === 0:
          return null;
        case offset === 1:
          return "au lendemain";
        case offset === 2:
          return "au surlendemain";
        default:
          return `à ${offset}j plus tard à`;
      }
    };

    var frequencyText = "";
    const weekDays: Weekday[] =
      this.rrule.options.byweekday.map((n: number) => new Weekday(n)) || [];
    switch (true) {
      case StaffingPlan.isSame(weekDays, StaffingPlan.everyDay):
        frequencyText = "tous les jours";
        break;
      case StaffingPlan.isSame(weekDays, StaffingPlan.mondayFriday):
        frequencyText = "du lundi au vendredi";
        break;
      case StaffingPlan.isSame(weekDays, StaffingPlan.mondaySaturday):
        frequencyText = "du lundi au samedi";
        break;
      default:
        frequencyText = StaffingPlan.humanJoin(
          weekDays.map((d: Weekday) => `${StaffingPlan.mapping[d.toString()]}s`)
        );
        break;
    }

    return `De ${fromHoursText} ${
      toDateOffsetFormat(this.daysSpanned) || "à"
    } ${toHoursText} ${frequencyText} à partir ${dateToText}`;
  };
}
