import { CSSProperties } from "react";
import styled from "styled-components";
import { rem } from "polished";
import { ifProp } from "styled-tools";

import { radius, transition } from "styles/const";
import { spaces, color } from "emoreg/const";

const TextAreaStyle = styled.textarea`
  width: 100%;
  padding: ${spaces[8]};
  border: 1px solid ${color.grey.base};
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
  min-height: ${rem(128)};
  border-radius: ${radius.base};
  ${ifProp("error", `border-color: ${color.red.base};`)}
  transition: 0.5s ${transition.cubicEaseInOut};
  ${ifProp(
    "disabled",
    `
    cursor: not-allowed;
    background: ${color.grey.light};
  `
  )}
  &:focus {
    border-color: ${color.blue.base};
    ${ifProp("error", `border-color: ${color.red.base};`)}
    outline: none;
    transition: 0.3s ${transition.cubicEaseInOut};
  }
`;

type TextAreaProps = {
  value: string;
  onChange: (e: any) => any;
  placeholder: string;
  error?: string | string[] | JSX.Element | null;
  autoComplete?: "disabled";
  id?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

export const TextArea = ({
  value,
  onChange,
  error,
  placeholder,
  autoComplete,
  id,
  disabled,
  style,
}: TextAreaProps) => (
  <TextAreaStyle
    autoComplete={autoComplete}
    id={id}
    disabled={disabled}
    error={error}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    style={style}
  />
);
