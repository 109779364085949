export const Errors = {
  INVALID: "INVALID",
  REQUIRED: "REQUIRED",
  PHONE_ALREADY_USE: "PHONE_ALREADY_USE",
  PHONE_REFUSED: "PHONE_REFUSED",
  PHONE_INVALID: "PHONE_INVALID",
  CAREGIVER_ALREADY_HAVE_XIMI_ID: "CAREGIVER_ALREADY_HAVE_XIMI_ID",
  XIMI_ID_ALREADY_BOUND_TO_OTHER: "XIMI_ID_ALREADY_BOUND_TO_OTHER",
  PROCESSING_ERROR: "PROCESSING_ERROR",
  PROCLIENT_ALREADY_HAVE_REFERENCE_CONTACT: "PROCLIENT_ALREADY_HAVE_REFERENCE_CONTACT",
  CAN_NOT_INSERT_OUIHELP_SERVICE_ON_PRESCRIBER: "CAN_NOT_INSERT_OUIHELP_SERVICE_ON_PRESCRIBER",
  CANNOT_CANCEL_DEBRIEFED_EVENT: "CANNOT_CANCEL_DEBRIEFED_EVENT",
  EVENT_ALREADY_DEBRIEFED: "EVENT_ALREADY_DEBRIEFED",
  CAREGIVER_INVALID_SSN: "CAREGIVER_INVALID_SSN",
  FORBIDDEN_CHARACTERS: "FORBIDDEN_CHARACTERS",
  MAIL_ALREADY_USE: "MAIL_ALREADY_USE",
  FILE_TYPE: "FILE_TYPE",
  IBAN_BIC_AND_ACCOUNT_OWNER_FULL_NAME_REQUIRED_TOGETHER:
    "IBAN_BIC_AND_ACCOUNT_OWNER_FULL_NAME_REQUIRED_TOGETHER",
  IBAN_BIC_MISMATCH: "IBAN_BIC_MISMATCH",
  CESU_PAYMENT_WITHOUT_CESU_SETTINGS: "CESU_PAYMENT_WITHOUT_CESU_SETTINGS",
  ONGOING_CALL_EVALUATION_ALREADY_EXISTS_FOR_PROCLIENT:
    "ONGOING_CALL_EVALUATION_ALREADY_EXISTS_FOR_PROCLIENT",
  CANCELLATION_REASON_REQUIRED: "CANCELLATION_REASON_REQUIRED",
  CANCELLATION_REASON_MORE_REQUIRED: "CANCELLATION_REASON_MORE_REQUIRED",
  UNKNOWN_USER: "UNKNOWN_USER",
  DELETED_USER: "DELETED_USER",
  LOGIN_ERROR: "LOGIN_ERROR",
  PDF_IS_CORRUPTED: "PDF_IS_CORRUPTED",
  PRICE_TOO_SMALL: "PRICE_TOO_SMALL",
  PRICE_TOO_EXPENSIVE: "PRICE_TOO_EXPENSIVE",
  PRICE_MISMATCH: "PRICE_MISMATCH",
  STRUCTURE_READ_ONLY_FROM_HB: "STRUCTURE_READ_ONLY_FROM_HB",
  WRONG_VALUE: "WRONG_VALUE",
  MISSING_PREREQUISITE_DATA: "MISSING_PREREQUISITE_DATA",
  BANK_ACCOUNT_ALREADY_BOUND_TO_DOCUMENT: "BANK_ACCOUNT_ALREADY_BOUND_TO_DOCUMENT",
  PROCLIENT_INVALID_STAGE: "PROCLIENT_INVALID_STAGE",
  COMPLETED_VEB_EVALUATION_CAN_NOT_BE_UPDATED: "COMPLETED_VEB_EVALUATION_CAN_NOT_BE_UPDATED",
  CANNOT_UPDATE_CARTS_IF_QUOTE_DOCUMENT_ALREADY_EXISTS:
    "CANNOT_UPDATE_CARTS_IF_QUOTE_DOCUMENT_ALREADY_EXISTS",
  CANNOT_UPDATE_MONET_SETTINGS_DATA_IF_SEPA_WARRANT_DOCUMENT_ALREADY_EXISTS:
    "CANNOT_UPDATE_MONET_SETTINGS_DATA_IF_SEPA_WARRANT_DOCUMENT_ALREADY_EXISTS",
  CAN_NOT_DEBRIEF_EVENT: "CAN_NOT_DEBRIEF_EVENT",
  PAYMENT_SETTING_ALREADY_SET_FOR_PROCLIENT_MEMBER:
    "PAYMENT_SETTING_ALREADY_SET_FOR_PROCLIENT_MEMBER",
} as const;

export type ErrorsType = keyof typeof Errors;

export const ErrorsMessages: Record<ErrorsType, string> = {
  INVALID: "Champ incorrect",
  REQUIRED: "Veuillez renseigner ce champ",
  PHONE_ALREADY_USE: "Ce numéro de téléphone es déjà attribué à quelqu'un",
  PHONE_REFUSED: "Le numéro de téléphone est incorrect",
  PHONE_INVALID:
    "Le numéro de téléphone est invalide. Veuillez préciser l'indicatif du numéro (Ex: +33). Sinon, vérifiez que le numéro est bien attribué",
  CAREGIVER_ALREADY_HAVE_XIMI_ID: "L'intervenant a déjà un compte Ximi associé",
  XIMI_ID_ALREADY_BOUND_TO_OTHER: "Cet identifiant Ximi est déjà associé à un autre intervenant",
  PROCESSING_ERROR: "Impossible de trouver un visage dans l'image envoyée",
  PROCLIENT_ALREADY_HAVE_REFERENCE_CONTACT: "Ce bénéficiaire a déjà un contact de référence",
  CAN_NOT_INSERT_OUIHELP_SERVICE_ON_PRESCRIBER:
    "Impossible  d'attribuer un service Ouihelp à un prescripteur",
  CANNOT_CANCEL_DEBRIEFED_EVENT:
    "Impossible d'annuler un évènement déjà débriefé, veuillez rafraichir la page",
  EVENT_ALREADY_DEBRIEFED: "Cet événèmenet a déjà été débriefé",
  CAREGIVER_INVALID_SSN: "Ce numéro de sécurité sociale est invalide",
  FORBIDDEN_CHARACTERS: "Votre texte contient des caractères non autorisés",
  MAIL_ALREADY_USE: "Le mail fourni est déjà utilisé",
  FILE_TYPE:
    "Le format du fichier importé n'est pas accepté. Veuiller importer un fichier au format PDF.",
  IBAN_BIC_AND_ACCOUNT_OWNER_FULL_NAME_REQUIRED_TOGETHER:
    "Veuillez fournir l'integralité des données bancaires",
  IBAN_BIC_MISMATCH: "L'IBAN et le BIC ne correspondent pas",
  CESU_PAYMENT_WITHOUT_CESU_SETTINGS:
    "Ce bénéficiaire n’a pas CESU comme mode de règlement principal." +
    " Éditez les modalités de facturation du bénéficiaire pour ajouter de nouveaux règlements par CESU.",
  ONGOING_CALL_EVALUATION_ALREADY_EXISTS_FOR_PROCLIENT:
    "Une instance d'appel existe déjà pour ce prospect",
  CANCELLATION_REASON_REQUIRED: "Veuillez fournir une raison d'annulation",
  CANCELLATION_REASON_MORE_REQUIRED:
    "Veuillez fournir plus d'informations sur la raison d'annulation",
  UNKNOWN_USER: "Ce compte utilisateur n'existe pas",
  DELETED_USER: "Ce compte utilisateur a été supprimé",
  LOGIN_ERROR: "Erreur de connexion inconnue",
  PDF_IS_CORRUPTED:
    "Le fichier que vous essayez d'ouvrir semble être corrompu et ne peut pas être utilisé",
  PRICE_TOO_SMALL: "Le prix est trop bas",
  PRICE_TOO_EXPENSIVE: "Le prix est trop élevé",
  PRICE_MISMATCH: "Le prix est différent de celui attendu",
  STRUCTURE_READ_ONLY_FROM_HB:
    "Cette structure est gérée depuis un CRM externe et ne peut être modifiée sur Hassibot",
  WRONG_VALUE: "La valeur est incorrecte",

  MISSING_PREREQUISITE_DATA: "Une information est manquante pour générer le document",
  BANK_ACCOUNT_ALREADY_BOUND_TO_DOCUMENT: "Le compte bancaire est déjà associé à un document",
  PROCLIENT_INVALID_STAGE: "Le bénéficiaire n'est plus dans le bon statut",
  COMPLETED_VEB_EVALUATION_CAN_NOT_BE_UPDATED: "La VEB ne peut plus être mise à jour",
  CANNOT_UPDATE_CARTS_IF_QUOTE_DOCUMENT_ALREADY_EXISTS:
    "Supprimez d'abord le devis pour mettre à jour l'accompagnement",
  CANNOT_UPDATE_MONET_SETTINGS_DATA_IF_SEPA_WARRANT_DOCUMENT_ALREADY_EXISTS:
    "Un mandat SEPA existe déjà",
  CAN_NOT_DEBRIEF_EVENT: "La visite a déjà été débriefée ou n'existe plus",
  PAYMENT_SETTING_ALREADY_SET_FOR_PROCLIENT_MEMBER:
    "Les paramètres de paiement sont déjà renseignés",
};

/* for more accurate error messages, we consume the extra to build message
 * use this instead of ErrorsMessages for better messages
 */
export const accurateErrorsMessages: Record<ErrorsType, string | ((extra: any) => string)> = {
  ...ErrorsMessages,
  PRICE_TOO_SMALL: ({ min }: { min: number }) =>
    `Le prix doit être supérieur ou égal à ${min / 100}€`,
  PRICE_TOO_EXPENSIVE: ({ max }: { max: number }) =>
    `Le prix doit être inférieur ou égal à ${max / 100}€`,
  PRICE_MISMATCH: ({ value }: { value: number }) => `Le prix doit être égal à ${value / 100}€`,
};

export const NOT_MAPPED = "Erreur inconnue (un petit mot à la tech plz)";

export const formatError = (
  error: string | undefined,
  extraMessages?: Record<string, string>
): string | undefined => (error ? messageFor(extraMessages)(error) : undefined);

export const messageFor =
  (extraMessages?: Record<string, string>) =>
  (key: string | undefined): string => {
    if (!key) {
      return NOT_MAPPED;
    }
    return extraMessages && key in extraMessages
      ? (extraMessages[key] as string)
      : key in ErrorsMessages
        ? (ErrorsMessages[key] as string)
        : NOT_MAPPED;
  };

const planningMessageMap: Record<string, string> = {
  PLAN_DURATION_TOO_LONG: "La durée de ce plan dépasse la plage horaire saisie",
  DATE_TO_MUCH_IN_THE_FUTURE:
    "Vous ne pouvez pas rechercher avec une date de début au delà de 4 semaines.",
};

export const planningErrorMessages = (key: string): string =>
  planningMessageMap && key in planningMessageMap
    ? (planningMessageMap[key] as string)
    : NOT_MAPPED;
