import amplitude from "amplitude-js";
import { History, UnregisterCallback } from "history";

import appConfig from "hassibot/app-config";
import {
  CallEvaluationUuid,
  CaregiverInterventionAbsenceUuid,
  CaregiverUuid,
  DeviceId,
  InterventionXimiId,
  ProclientUuid,
  VebEvaluationUuid,
} from "hassibot/services_v2/common/types";
import { ProclientPlans } from "hassibot/services_v2/staffing/types";
import { useEffect } from "react";

export const instance = amplitude.getInstance();

export const initAmplitude = (deviceId: DeviceId) => {
  instance.init(import.meta.env.VITE_AMPLITUDE);
  instance.setDeviceId(deviceId);
  instance.setVersionName(appConfig.BUILD_HASH);
};
export const useAmplitudeScreenSizeLog = () => {
  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    instance.setUserProperties({
      "Screen Width": screenWidth,
      "Screen Height": screenHeight,
    });
  }, []);
};
const eventPageView = (path: string): void => {
  instance.logEvent("PAGE_VIEW", { pathname: path });
};

export const registerPageView = (history: History): UnregisterCallback => {
  eventPageView(history.location.pathname);
  return history.listen(location => {
    eventPageView(location.pathname);
  });
};

class CaregiversSearch {
  searchRequestEvent = (
    isProclientSearch: boolean | null,
    searchType: "plan" | "datetime" | null
  ) =>
    instance.logEvent("MATCHING3_SEARCH_REQUEST", {
      isProclientSearch: isProclientSearch ? isProclientSearch : false,
      searchType: searchType ? searchType : "empty",
    });

  searchResponseEvent = (
    withCollisions: boolean | null,
    durationMs: number,
    displayed: boolean | null
  ) =>
    instance.logEvent("MATCHING3_SEARCH_RESPONSE", {
      withCollisions: withCollisions,
      durationMs: durationMs,
      displayed: displayed,
    });

  searchEditEvent = (changedField: string) =>
    instance.logEvent("MATCHING3_SEARCH_EDIT", { changedField: changedField });

  caregiverAskEvent = (caregiverUuid: string) =>
    instance.logEvent("MATCHING3_CAREGIVER_ASK", { caregiverUuid: caregiverUuid });

  tabSessionEvent = () => instance.logEvent("MATCHING3_TAB_SESSION");

  addCaregiverManually = (caregiverUuid: CaregiverUuid, proclientUuid: ProclientUuid | undefined) =>
    instance.logEvent("ADD_CAREGIVER_MANUALLY", {
      caregiverUuid: caregiverUuid,
      proclientUuid: proclientUuid,
    });

  addFlexibleDaysEvent = () => instance.logEvent("ADD_FLEXIBLE_DAYS");
}

export const caregiversSearch = new CaregiversSearch();

// COMPASS

class Compass {
  // Plans
  staffedPlan = (plan: ProclientPlans) =>
    instance.logEvent("PENDING_PLANS_STAFFED", { plan: plan });

  seeMorePlans = () => instance.logEvent("PENDING_PLANS_SEE_MORE");

  // QaBatch Interventions
  endIntervention = (QaBatchUuid: string) =>
    instance.logEvent("END_INTERVENTION_DONE", { QaBatchUuid: QaBatchUuid });

  openChatIntervention = () => instance.logEvent("END_INTERVENTION_CHAT");

  // QaBatch Caregivers

  endPulseCheck = (QaBatchUuid: string) =>
    instance.logEvent("PULSE_CHECK_DONE", { QaBatchUuid: QaBatchUuid });

  openChatPulseCheck = () => instance.logEvent("PULSE_CHECK_CHAT");

  displayed = (section: string) => instance.logEvent("COMPASS_DISPLAYED", { section });

  // Intervention replacements
  openXimiPlanning = (interventionToReplaceId: InterventionXimiId) =>
    instance.logEvent("REPLACE_INTERVENTION", { interventionToReplaceId });

  adjustXimiPlanning = (
    interventionToReplaceId: InterventionXimiId,
    caregiverUuid: CaregiverUuid
  ) =>
    instance.logEvent("ADJUST_CAREGIVER_XIMI_PLANNING", {
      interventionToReplaceId,
      caregiverUuid,
    });

  createXimiContract = (
    interventionToReplaceId: InterventionXimiId,
    caregiverUuid: CaregiverUuid
  ) =>
    instance.logEvent("CREATE_CONTRACT_IN_XIMI", {
      interventionToReplaceId,
      caregiverUuid,
    });

  assignXimiIntervention = (
    interventionToReplaceId: InterventionXimiId,
    caregiverUuid: CaregiverUuid
  ) =>
    instance.logEvent("ASSIGN_XIMI_INTERVENTION", {
      interventionToReplaceId,
      caregiverUuid,
    });
}

export const compassAmplitude = new Compass();

class Intervention {
  // Caregiver Absence Declarations
  openXimiPlanning = (caregiverInterventionAbsenceUuid: CaregiverInterventionAbsenceUuid) =>
    instance.logEvent("DECLARE_XIMI_ABSENCE", { caregiverInterventionAbsenceUuid });
  openVacantXimiPlanning = () => instance.logEvent("VACANT_XIMI_PLANNING");
}
export const interventionAmplitude = new Intervention();

class CallEvaluation {
  // Call Evaluation
  newCallExistingProfile = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_OPEN_NEW_CALL_EXISTING_PROSPECT", {
      uuid,
    });
  editCallExistingProfile = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_OPEN_EDIT_CALL_EXISTING_PROSPECT", {
      uuid,
    });
  newCallNewProfile = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_OPEN_NEW_CALL_NEW_PROSPECT", { uuid });
  complete = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_COMPLETE_CALL_PROSPECT", { uuid });
  completeOutOfArea = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_COMPLETE_CALL_PROSPECT_OUT_OF_AREA", { uuid });
  downgrade = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_DOWNGRADE_CALL_PROSPECT", { uuid });
  gone = (uuid: CallEvaluationUuid) => instance.logEvent("ACTION_GONE_CALL_PROSPECT", { uuid });
  noPickup = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_NO_PICK_UP_CALL_PROSPECT", { uuid });
  callBackLater = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_CALL_BACK_LATER_CALL_PROSPECT", { uuid });
  completeLater = (uuid: CallEvaluationUuid) =>
    instance.logEvent("ACTION_COMPLETE_CALL_PROSPECT_LATER", { uuid });
}
export const callEvaluationAmplitude = new CallEvaluation();

class VebEvaluation {
  // Call Evaluation
  newVebEvaluation = (uuid: VebEvaluationUuid) =>
    instance.logEvent("ACTION_OPEN_NEW_VEB_EVALUATION", {
      uuid,
    });
  editVebEvaluation = (uuid: VebEvaluationUuid) =>
    instance.logEvent("ACTION_OPEN_EDIT_VEB_EVALUATION", {
      uuid,
    });
}
export const vebEvaluationAmplitude = new VebEvaluation();
