import { ResponsiveProps } from "emoreg/types";

/**
 * Converts a naive property to a responsive property.
 *
 * @param {string | ResponsiveProps<string> | undefined | null} property - The property to convert.
 * @returns {ResponsiveProps<string>} The converted responsive property.
 */
export function naiveToResponsiveProp(
  property: string | ResponsiveProps<string>
): ResponsiveProps<string>;
export function naiveToResponsiveProp(
  property: string | ResponsiveProps<string> | undefined | null
): ResponsiveProps<string>;
export function naiveToResponsiveProp(
  property: string | ResponsiveProps<string> | undefined | null
): ResponsiveProps<string> {
  if (typeof property === "object" && property !== null) {
    return property;
  } else {
    return {
      mobile: property ?? "",
      tablet: property ?? "",
    };
  }
}

/**
 * Converts all properties in the given object to responsive properties.
 *
 * @template T
 * @param {T} props - The object containing properties to convert.
 * @returns {Record<string, ResponsiveProps<string>>} The object with converted responsive properties.
 */
export const toResponsiveProps = <T extends object>(
  props: T
): Record<string, ResponsiveProps<string>> => {
  const responsivePropsArray = Object.entries(props).map(([key, value]) => [
    key,
    naiveToResponsiveProp(value),
  ]);
  return Object.fromEntries(responsivePropsArray);
};

/**
 * Extracts the value for a specific breakpoint from a set of responsive properties.
 *
 * @param {Record<string, ResponsiveProps<any>>} responsiveProps - The responsive properties.
 * @param {"mobile" | "tablet"} breakpoint - The breakpoint to extract.
 * @returns {Record<string, any>} The values for the specified breakpoint.
 */
export const extractBreakpointFromResponsiveProps = (
  responsiveProps: Record<string, ResponsiveProps<any>>,
  breakpoint: "mobile" | "tablet"
): Record<string, any> => {
  const extractedProps = Object.entries(responsiveProps).reduce(
    (accumulator, [key, value]) => {
      accumulator[key] = value[breakpoint];
      return accumulator;
    },
    {} as Record<string, any>
  );
  return extractedProps;
};
