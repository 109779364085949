import appConfig from "hassibot/app-config";
import { css } from "styled-components";

import { device } from "styles/const";
import { color } from "emoreg/const";

type ResponsiveHelpersKeys =
  | "forMobileUp"
  | "forPortraitTabletUp"
  | "forLandscapeTabletUp"
  | "forDesktopUp"
  | "belowMobile"
  | "belowPortraitTablet"
  | "belowLandscapeTablet"
  | "belowDesktop";

type ResponsiveHelpers = Record<ResponsiveHelpersKeys, (...args: any[]) => string>;
/**
 * @deprecated
 * @description import from emoreg/utils/responsive instead
 */
export const responsiveHelpers = {
  ...Object.entries(device).reduce((generatedHelpers, [label, size]): ResponsiveHelpers => {
    const labelReworked = label.charAt(0).toUpperCase() + label.slice(1);

    return {
      ...generatedHelpers,
      [`for${labelReworked}Up`]: (...args) => css`
        @media (min-width: ${size / 16}em) {
          ${css(...args)};
        }
      `,
      [`below${labelReworked}`]: (...args) => css`
        @media (max-width: ${(size - 1) / 16}em) {
          ${css(...args)};
        }
      `,
    };
  }, {} as ResponsiveHelpers),
};

export const computeEnvBackground = (to: string) =>
  appConfig.IS_TEST_ENVS
    ? `linear-gradient(to ${to}, #8e2de2, #4a00e0);`
    : appConfig.IS_DEV
      ? `linear-gradient(to ${to}, orange , yellow, green, cyan, blue, violet)`
      : color.blue.dark;

export const computedHorizontalEnvBackground = computeEnvBackground("right");
export const computedVerticalEnvBackground = computeEnvBackground("bottom");
