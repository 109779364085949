import { CSSProperties, PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";

import { radius } from "styles/const";
import { color, spaces } from "emoreg/const";

enum AlertType {
  WARNING = "warning",
  NEUTRAL = "neutral",
}

type AlertProps = {
  type: AlertType;
  style?: CSSProperties;
};

const AlertContainer = styled.div`
  height: auto;
  padding: ${spaces[8]} ${spaces[12]};
  border-radius: ${radius.lg};
  font-size: ${rem(12)};
  ${props => computeType(props.type)};
`;

const computeType = (type: AlertType) => {
  switch (type) {
    case AlertType.WARNING:
      return css`
        background: ${color.orange.lighter};
        color: ${color.orange.dark};
      `;
    case AlertType.NEUTRAL:
      return css`
        background: ${color.grey.light};
        color: ${color.grey.darker};
      `;
  }
};

const Alert = (props: PropsWithChildren<AlertProps>) => (
  <AlertContainer style={props.style} type={props.type}>
    {props.children}
  </AlertContainer>
);

//
// Abstraction of Alert exposed to the app
//
type AlertAbstractionProps = {
  style?: CSSProperties;
};

/* @deprecated use Notice instead */
export const AlertWarning = (props: PropsWithChildren<AlertAbstractionProps>) => (
  <Alert style={props.style} type={AlertType.WARNING}>
    {props.children}
  </Alert>
);
/**
 * @see AlertTips If you want to implement a "hey par la" kind of Alert do not use this primitive pls
 * @deprecated use Notice instead
 */
export const AlertNeutral = (props: PropsWithChildren<AlertAbstractionProps>) => (
  <Alert style={props.style} type={AlertType.NEUTRAL}>
    {props.children}
  </Alert>
);
/**
 * @func AlertTips is an abstraction of the components `AlertNeutral` used to factorise the Tips usage.
 * @tutorial https://hb.ouihelp.fr/ia/proclients?owner=all&origin=all&stage=all at the bottom of the page
 * @deprecated use Notice instead
 */
export const AlertTips = (props: PropsWithChildren<AlertAbstractionProps>) => (
  <AlertNeutral style={props.style}>
    <p>
      <strong>Hey par là : </strong>
      {props.children}
    </p>
  </AlertNeutral>
);
