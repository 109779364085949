import styled from "styled-components";

export const AddDocumentTemplateStyle = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-rows: auto auto;
`;

export const WrapperLoader = styled.div`
  align-self: center;
  & > div {
    display: flex;
    align-items: center;
  }
`;
