import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";

import { font, radius } from "styles/const";
import { color, spaces } from "emoreg/const";

const generateTagStyle = backgroundColor => textColor => {
  return css`
    background: ${backgroundColor};
    color: ${textColor};
  `;
};

export class TypesMapping {
  primary = generateTagStyle(color.blue.base)("#fff");
  secondary = generateTagStyle(color.grey.dark)("#fff");
  neutral = generateTagStyle(color.grey.light)(color.grey.dark);
  info = generateTagStyle("#17a2b8")("#fff");
  danger = generateTagStyle(color.red.base)("#fff");
  dangerCovid = generateTagStyle("#fff")(color.red.base);
  successCovid = generateTagStyle("#fff")("#128A0C");
  cancel = generateTagStyle("#FA8700")("#fff");
  dark = generateTagStyle("#343a40")("#fff");
  success = generateTagStyle("#128A0C")("#fff");
  warning = generateTagStyle(color.orange.dark)("#fff");
  tertiary = generateTagStyle(color.grey.base)("#fff");
  light = generateTagStyle("#fff")(color.grey.dark);
}

const typesMapping = new TypesMapping();
export type TagTypes = keyof TypesMapping;

const TagStyle = styled.span`
  font-size: ${rem(12)};
  padding: ${spaces[4]} ${spaces[8]};
  font-weight: ${font.weight.bold};
  border-radius: ${radius.lg};
  white-space: nowrap;
  ${({ type }: { type: TagTypes }) => typesMapping[type]}
`;

type TagProps = { type: TagTypes; style?: React.CSSProperties; title?: string };
/**
 * @deprecated use Tag from emoreg/atoms/Tag instead
 */
export const Tag = ({ children, type, style, title }: PropsWithChildren<TagProps>) => (
  <TagStyle style={style} type={type} title={title}>
    {children}
  </TagStyle>
);
