import { color, radius, spaces } from "emoreg/const";
import { PropsWithChildren } from "react";
import styled, { css } from "styled-components";

const typeToColor = {
  red: {
    background: color.red.light,
    color: color.red.base,
    border: color.red.base,
  },
  green: {
    background: color.green.light,
    color: color.green.base,
    border: color.green.base,
  },
  orange: {
    background: color.orange.light,
    color: color.orange.dark,
    border: color.orange.dark,
  },
  grey: {
    background: color.grey.light,
    color: color.grey.dark,
    border: color.grey.light,
  },
  blue: {
    background: color.blue.lighter,
    color: color.grey.dark,
    border: color.blue.lighter,
  },
};
const TipStyle = styled.div`
  ${props => css`
    padding: ${spaces[12]} ${spaces[16]};
    background-color: ${props.backgroundColor};
    border-radius: ${radius.base};
    border-style: ${props.borderColor ? "solid" : "hidden"};
    border-width: 1px;
    border-color: ${props.borderColor};
    overflow-wrap: anywhere;
  `}
`;
export type NoticeType = keyof typeof typeToColor;
export const Notice = (
  props: PropsWithChildren<{
    type: NoticeType;
    className?: string;
    withBorder?: boolean;
  }>
) => (
  // Here we pass the className props to be able to override the component style by doing
  // const StyledNotice = styled(Notice)`...styleOverrides...`;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  <TipStyle
    backgroundColor={typeToColor[props.type].background}
    borderColor={props.withBorder ? typeToColor[props.type].border : undefined}
    className={props.className}
  >
    <div style={{ color: typeToColor[props.type].color }}>{props.children}</div>
  </TipStyle>
);
