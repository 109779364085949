import styled from "styled-components";
import { rem } from "polished";
import { radius } from "styles/const";
import { spaces } from "emoreg/const";

export const NavLinkIcon = styled.a`
  margin-left: ${spaces[16]};
  background: ${props => (props.isNextEnv ? "#690909" : "#002a54")};
  border-radius: ${radius.base};
  padding: ${rem(7)} ${rem(13)};
  display: inline-block;
  & > span {
    color: white;
  }
  &:hover {
    text-decoration: none;
  }
`;
