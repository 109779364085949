/**
 * This module implements a "line height hack" to allow our text
 * components to have no external margin, including the one coming from
 * line height.
 *
 * It is extracted from:
 *    https://medium.com/eightshapes-llc/cropping-away-negative-impacts-of-line-height-84d744e016ce
 */
import { css } from "styled-components";

export const lhCrop = (lineHeight: number) => css`
  &::before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((${lineHeight} - 1) * 0.5em * -1);
  }

  &::after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    margin-bottom: calc((${lineHeight} - 1) * 0.5em * -1);
  }
`;
