import { useEffect, useState } from "react";
import { BehaviorSubject } from "rxjs";
import * as Sentry from "@sentry/react";
import amplitude from "amplitude-js";

import { Store, get, set, del } from "custom-idb-keyval";
import {
  BROADCAST_CHANNEL_LOGIN,
  BROADCAST_CHANNEL_LOGIN_SIGNIN,
  BROADCAST_CHANNEL_LOGIN_SIGNOUT,
  emitOnBroadcastChannel,
  listenOnBroadcastChannel,
} from "custom-broadcast-channel";

type UserCredentials = {
  token: string;
  email: string;
  id: string;
};

const authStore = new Store("hassibot-config2", "config-store");
const USER_CREDS = "USER_CREDS";

/**
 * User credentials observable (strictly speaking a "behavior subject"). Emit
 * each time the "logged in / logged out" state changes.
 *
 * - `undefined` is the initial state, when no creds have been fetch from IDB
 * yet.
 * - `null` is the "logged out" state.
 * - `UserCredentials` being the "logged in" state.
 */
export const userCredentialsSubject = new BehaviorSubject<UserCredentials | null | undefined>(
  undefined
);

export const useUserCredentialsSubject = () => {
  const [userCreds, setUserCreds] = useState<UserCredentials | null | undefined>(
    userCredentialsSubject.getValue()
  );

  useEffect(() => {
    const subscription = userCredentialsSubject.subscribe(newUserCreds =>
      setUserCreds(newUserCreds)
    );
    return () => subscription.unsubscribe();
  }, []);

  return userCreds;
};

/**
 * Set user credentials in IDB and broadcast the new state to the all tabs.
 * Main entrypoint into the "sign in" logic.
 *
 * This will be called once in the tab initiating the sign in.
 */
export const setUserCredentials = (userCredentials: UserCredentials) =>
  set(USER_CREDS, userCredentials, authStore).then(() => {
    emitOnBroadcastChannel(BROADCAST_CHANNEL_LOGIN, {
      type: BROADCAST_CHANNEL_LOGIN_SIGNIN,
      userCredentials,
    });
  });

/**
 * Delete user credentials from IDB and broadcast the new state to the all
 * tabs. Main entrypoint into the "sign out" logic.
 *
 * This will be called once in the tab initiating the sign out (manually via
 * the sign out button or following a 401 of the API).
 *
 * This hard data race! We should lock IDB to ensure that the previous user
 * creds are still there, then delete them and broadcast if-and-only-if we're
 * the tab having deleted them (to ensure "exactly one" broadcast).
 *
 * Otherwise the risk is for multiple tab to sign out at the same time (if
 * multiple tabs are doing API requests for instance), then we'd get multiple
 * "BROADCAST_CHANNEL_LOGIN_SIGNOUT" broadcast.
 *
 * Worse, one of those "BROADCAST_CHANNEL_LOGIN_SIGNOUT" broadcast might arrive
 * after a tab has successfuly re-signed in, triggering "sign out right after
 * sign in" bug.
 */
export const deleteUserCredentials = () =>
  del(USER_CREDS, authStore).then(() => {
    // Signal every tab (including the current one) that we're out.
    emitOnBroadcastChannel(BROADCAST_CHANNEL_LOGIN, { type: BROADCAST_CHANNEL_LOGIN_SIGNOUT });
  });

// Called once at "boot time" to initialize `userCredentialsSubject` with IDB's
// value.
get<UserCredentials | undefined>(USER_CREDS, authStore).then(userCredentials => {
  userCredentialsSubject.next(userCredentials ? userCredentials : null);
});

// Follow and replicated broadcasted state into "local" (the one being observed
// in this tab) state.
listenOnBroadcastChannel(BROADCAST_CHANNEL_LOGIN, msg => {
  // A tab (maybe our own) has initiated a signout.
  if (msg.data.type === BROADCAST_CHANNEL_LOGIN_SIGNOUT) {
    userCredentialsSubject.next(null);
  }

  // A tab (maybe our own) has logged in.
  if (msg.data.type === BROADCAST_CHANNEL_LOGIN_SIGNIN) {
    userCredentialsSubject.next(msg.data.userCredentials);
  }
});

//Follow our state to properly configure Sentry and Amplitude.
userCredentialsSubject.subscribe(newUserCreds => {
  const instance = amplitude.getInstance();

  if (newUserCreds) {
    Sentry.configureScope(scope => {
      scope.setUser({ email: newUserCreds.email });
    });
    instance.setUserId(newUserCreds.email);
  } else {
    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
    instance.setUserId(null);
  }
});
