import { Buffer } from "buffer/"; // The final slash is important, don't remove it
import appConfig from "hassibot/app-config";
import { parse, stringify, v4 as uuidv4FromDep } from "uuid";

// See https://github.com/taskcluster/slugid
export function shorten(uuid_: string) {
  let bytes;
  try {
    bytes = parse(uuid_);
  } catch (e) {
    console.warn("Can't parse following UUID", uuid_);
    throw e;
  }
  const base64 = Buffer.from(bytes).toString("base64");
  return base64
    .replace(/\+/g, "-") // Replace + with - (see RFC 4648, sec. 5)
    .replace(/\//g, "_") // Replace / with _ (see RFC 4648, sec. 5)
    .substring(0, 22); // Drop '==' padding
}

export function unshorten(slug: string) {
  if (slug.length !== 22 || slug.includes("\\") || slug.includes("+")) {
    const err = {
      type: 400,
    };
    throw err;
  }

  const base64 = slug.replace(/-/g, "+").replace(/_/g, "/") + "==";
  return stringify(Buffer.from(base64, "base64"));
}

export const uuidv4 = (failsafe?: boolean): string => {
  // `crypto.randomUUID()` is not available on non-secure HTTP context
  // (except localhost), it's troublesome when sharing our local env
  // over the network (with tailscale for instance), hence this
  // workaround to relax security when `appConfig.IS_DEV`.
  // Regarding `failsafe`, in certain scenarii we *need* a way to forge
  // a UUID even if the forged UUID is "less sure/random" than it could
  // be. Such a scenario is the app boot time, when we need a device ID
  // before showing the user a "non supported browser" screen.
  if ((appConfig.IS_DEV || failsafe) && !crypto.randomUUID) {
    return uuidv4FromDep();
  }
  return crypto.randomUUID();
};
