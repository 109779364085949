import React, { useEffect, useContext, createContext, useState } from "react";
import * as Sentry from "@sentry/react";

import { mtLogger, urlB64ToUint8Array } from "shared-core";
import { OhApi } from "siayuda";
import { serviceWorkerRegistration } from "sw-registration";
import {
  listenOnBroadcastChannel,
  BROADCAST_CHANNEL_LOGIN,
  BROADCAST_CHANNEL_LOGIN_SIGNOUT,
} from "custom-broadcast-channel";

import { startSession as startSessionService } from "./hassibot/services_v2/login";
import { SessionCollaborator } from "hassibot/services_v2/login/types";
import { sessionCollaboratorReader } from "hassibot/services_v2/login/reader";

const SESSION_STORAGE_COLLABORATOR_KEY = "SESSION_COLLABORATOR";

// Improvements: If the HTTP call fails (for instance because we have no
// network) and if the call succeeded previously in a precedent session it
// would be better to fallback to this data instead of hard failing the whole
// application.
const startSessionAndCheckPushTokenValidity = async (
  pushTokenSubscription: PushSubscription | undefined
): Promise<[boolean, SessionCollaborator]> => {
  const response = await startSessionService(JSON.stringify(pushTokenSubscription));

  if (OhApi.isSuccess(response)) {
    const hassibotCollaborator = response.value;
    if (hassibotCollaborator["validPushToken"]) {
      mtLogger.debug("Session started with a valid push subscription");
      return [true, hassibotCollaborator];
    } else {
      mtLogger.debug("Session started without a valid push subscription");
      return [false, hassibotCollaborator];
    }
  }

  let error: Error;
  if (OhApi.isEmpty(response)) {
    error = new Error("Session start failed: Empty response from API");
  } else {
    error = new Error(`Session start failed: ${response.kind}`);
  }
  Sentry.captureException(error, {
    fingerprint: ["startSessionAndCheckPushTokenValidity-api-fail"],
  });
  throw error;
};

const SessionCollaboratorContext = createContext<SessionCollaborator | null>(null);

export const useSessionCollaboratorContext = (): SessionCollaborator => {
  const realContext = useContext(SessionCollaboratorContext);
  if (realContext === null) {
    throw new Error("useSessionCollaboratorContext must be used within SessionContainer");
  }

  return realContext;
};

const getOrCreateSubscription = async (): Promise<{
  subscription: PushSubscription | undefined;
  isFreshSubscription: boolean;
}> => {
  if (!serviceWorkerRegistration) {
    return { subscription: undefined, isFreshSubscription: false };
  }

  if (!import.meta.env.VITE_VAPID_PUBLIC) {
    mtLogger.error("There is not application server key");
    return { subscription: undefined, isFreshSubscription: false };
  }

  if (!("PushManager" in window)) {
    mtLogger.error("Push notifications are not supported");
    return { subscription: undefined, isFreshSubscription: false };
  }

  const registration = await serviceWorkerRegistration;

  const applicationServerKey = urlB64ToUint8Array(import.meta.env.VITE_VAPID_PUBLIC);

  const currentSubscription = await registration.pushManager.getSubscription();

  if (currentSubscription) {
    // Does the PushToken match our application server key ?
    if (
      currentSubscription.options.applicationServerKey &&
      new Uint8Array(currentSubscription.options.applicationServerKey).toString() ===
        applicationServerKey.toString()
    ) {
      mtLogger.debug("PushSubscription is active");
      return { subscription: currentSubscription, isFreshSubscription: false };
    }

    mtLogger.debug("An invalid PushSubscription is active, unsubscribing it");
    await currentSubscription.unsubscribe();
  }

  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey,
  };
  let newSubscription: PushSubscription | undefined;
  try {
    newSubscription = await registration.pushManager.subscribe(subscribeOptions);
  } catch {
    mtLogger.warn("PushSubscription is disabled (probably a permission denied)");
    return { subscription: undefined, isFreshSubscription: false };
  }

  return { subscription: newSubscription, isFreshSubscription: true };
};

/**
 * This container is used to start a session with the API. It means we already
 * have a valid auth token (cached or just negotiated), we ping the API to get
 * the "session collaborator" and to check if the push token is still valid.
 *
 * PushTokens are generated by the browser dialoguing with the browser
 * provider. When the browser get the current registration, it can get an
 * outdated one with no way to know. That's why Ouihelp backend store outdated
 * (that get rejected from the notification service) token. When the browser
 * try to start a session with an outdated token, the API send a specific
 * response to invalidated the token browser-side and generate a new one.
 */
export const SessionContainer: React.FC<{}> = ({ children }) => {
  const [sessionCollaborator, setSessionCollaborator] = useState<SessionCollaborator | null>(null);
  /**
   * Request the API to ensure our current push token is registered and
   * valid. Return wether the current subscription is valid (meaning: not
   * expired) and can be kept without re-subscription.
   */
  useEffect(() => {
    const startSessionSaga = async () => {
      const { subscription, isFreshSubscription } = await getOrCreateSubscription();
      const [serverPushIsOK, hbCollaborator] =
        await startSessionAndCheckPushTokenValidity(subscription);

      if (subscription && !isFreshSubscription && !serverPushIsOK) {
        // We might have an invalid current subscription. Let's try to
        // unsubscribe/resubscribe once.
        subscription.unsubscribe();
        const { subscription: newSubscription } = await getOrCreateSubscription();
        await startSessionAndCheckPushTokenValidity(newSubscription);
      }

      sessionStorage.setItem(SESSION_STORAGE_COLLABORATOR_KEY, JSON.stringify(hbCollaborator));
      setSessionCollaborator(hbCollaborator);
    };

    const sessionCollaborator = sessionStorage.getItem(SESSION_STORAGE_COLLABORATOR_KEY);
    if (sessionCollaborator) {
      setSessionCollaborator(sessionCollaboratorReader(JSON.parse(sessionCollaborator)));
    } else {
      startSessionSaga();
    }
  }, []);

  /**
   * Clears our state when signing out.
   */
  useEffect(() => {
    return listenOnBroadcastChannel(BROADCAST_CHANNEL_LOGIN, msg => {
      if (msg.data === BROADCAST_CHANNEL_LOGIN_SIGNOUT) {
        sessionStorage.removeItem(SESSION_STORAGE_COLLABORATOR_KEY);
      }
    });
  }, []);

  // Hard stops before having a session collaborator. Cf "Improvement" above.
  if (!sessionCollaborator) {
    return null;
  }

  return (
    <SessionCollaboratorContext.Provider value={sessionCollaborator}>
      {children}
    </SessionCollaboratorContext.Provider>
  );
};
