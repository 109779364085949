import { css } from "styled-components";

export const switchProp = <P extends Record<string, any>, S extends keyof P>(
  s: S,
  o: Partial<Record<P[S], string>>
) => {
  return css`
    ${(props: P) => o[props[s]]}
  `;
};
