import { isSuccess } from "./guards";
import { handleResponsePromise, timeoutError } from "./internal/helpers";
import { ApiResponse, ApiV2ClientConfig, Reader } from "./types";

const withTimeout = <T>(
  apiReponsePromise: Promise<ApiResponse<T>>,
  request: Request,
  callTimeoutMs: number
): Promise<ApiResponse<T>> => {
  const timeoutPromise: Promise<ApiResponse<T>> = new Promise((resolve) =>
    setTimeout(() => resolve(timeoutError(request, callTimeoutMs)), callTimeoutMs)
  );
  return Promise.race([apiReponsePromise, timeoutPromise]);
};

export class ApiV2Client {
  constructor(public config: ApiV2ClientConfig = { auth: null }) {}

  public buildHeaders = (headers?: Record<string, string>) =>
    new Headers({
      ...(this.config.auth && this.config.auth.makeAuthHeader()),
      ...headers,
    });

  private handleRequest = <T>(timeoutMs: number | undefined) => (
    request: Request
  ): Promise<ApiResponse<T>> => {
    const callTimeoutMs = timeoutMs || this.config.defaultTimeoutMs;
    const responsePromise = fetch(request);

    const responseHandler = () =>
      handleResponsePromise<T>(request, responsePromise, this.config.preProcessingResponseHooks);

    return callTimeoutMs
      ? withTimeout(responseHandler(), request, callTimeoutMs)
      : responseHandler();
  };

  updateToken = (newToken: string): void => {
    this.config.auth = this.config.auth?.clone({ token: newToken }) || null;
  };

  get = <T>(
    url: string,
    headers?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.handleRequest<T>(timeoutMs)(
      new Request(url, {
        method: "GET",
        headers: this.buildHeaders(headers),
      })
    );

  post = <T>(
    url: string,
    payload?: FormData | string,
    headers?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.handleRequest<T>(timeoutMs)(
      new Request(url, {
        method: "POST",
        headers: this.buildHeaders(headers),
        ...(payload && { body: payload }),
      })
    );

  put = <T>(
    url: string,
    payload?: FormData | string | null,
    headers?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.handleRequest<T>(timeoutMs)(
      new Request(url, {
        method: "PUT",
        headers: this.buildHeaders(headers),
        ...(payload && { body: payload }),
      })
    );

  // TODO rework this
  httpDelete = <T>(
    url: string,
    payload?: FormData | string,
    headers?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.handleRequest<T>(timeoutMs)(
      new Request(url, {
        method: "DELETE",
        headers: this.buildHeaders(headers),
        ...(payload && { body: payload }),
      })
    );

  postJson = <T>(
    url: string,
    payload?: {},
    extraHeaders?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.post(
      url,
      JSON.stringify(payload),
      {
        ...extraHeaders,
        "Content-Type": "application/json",
      },
      timeoutMs
    );

  putJson = <T>(
    url: string,
    payload?: {},
    extraHeaders?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.put(
      url,
      JSON.stringify(payload),
      {
        ...extraHeaders,
        "Content-Type": "application/json",
      },
      timeoutMs
    );

  deleteJson = <T>(
    url: string,
    payload?: {},
    extraHeaders?: Record<string, string>,
    timeoutMs?: number
  ): Promise<ApiResponse<T>> =>
    this.httpDelete(
      url,
      JSON.stringify(payload),
      {
        ...extraHeaders,
        "Content-Type": "application/json",
      },
      timeoutMs
    );
}

export const applyReader = <In, Out>(reader: Reader<In, Out>) => (
  resp: ApiResponse<In>
): ApiResponse<Out> =>
  isSuccess(resp)
    ? {
        ...resp,
        value: reader(resp.value),
      }
    : resp;

export const applyAsyncReader = <In, Out>(reader: Reader<In, Promise<Out>>) => (
  resp: ApiResponse<In>
): Promise<ApiResponse<Out>> =>
  isSuccess(resp)
    ? reader(resp.value).then((value) => ({
        ...resp,
        value,
      }))
    : Promise.resolve(resp);
