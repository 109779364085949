export const transition = {
  cubicEaseInOut: "cubic-bezier(0.785, 0.135, 0.15, 0.86)",
};

// hover remove
export const shadow = {
  card: {
    base: "0px 1px 4px rgba(0, 0, 0, 0.15)",
    hover: "",
  },
};

// Peut être plus besoin de base ?
export const radius = {
  base: "2px",
  lg: "4px",
};
// to remove
export const gutter = {
  base: 32,
};
export type FontStyle = "italic" | "normal";
// light et semibold remove
export type FontWeight = 300 | 400 | 600 | 700;
export const font: { weight: Record<"light" | "normal" | "semiBold" | "bold", FontWeight> } = {
  weight: {
    light: 300,
    normal: 400,
    semiBold: 600,
    bold: 700,
  },
};
// gardons uniquement mobile: 1024
export const device = {
  mobile: 480,
  portraitTablet: 768,
  landscapeTablet: 1024,
  desktop: 1200,
};
