import { BotInfo, BrowserInfo, NodeInfo, OperatingSystem } from "detect-browser";
import jwtDecode from "jwt-decode";
import { rem } from "polished";
import { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { digestGoogleAuthLogin } from "hassibot/services_v2/login";

import { color, spaces } from "emoreg/const";
import appConfig from "hassibot/app-config";
import { XimiMigrationWarningAtConnection } from "hassibot/component/maintenance-mode/XimiMigrationWarning";
import { Button, ButtonWrapper, Emoji, Title } from "styles/atoms";
import { font, radius } from "styles/const";
import { messageFor } from "./hassibot/error-messages";

/**
 *  In this file you will find the full-screen components that are not Hassibot.
 *  Things like 404, login, error, ...
 */
const StandaloneAppFrameStyle = styled.div`
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const WrapperGoogleConnect = styled.div`
  flex 0 1;
  height: ${rem(430)};
  border: 1px solid ${color.grey.base};
  border-radius: ${rem(radius.base)};
  padding: ${spaces[32]};
`;
const TextLogin = styled.p`
  font-size: ${spaces[12]};
  color: ${color.grey.darker};
  margin: 0;
`;
const TextLoginHighlight = styled.span`
  background: "#eee";
  padding: ${rem(3)} ${rem(6)};
  font-weight: ${font.weight.bold};
`;
const XimiMigrationWarning = styled.div`
  text-align: left;
  justify-content: left;
  align-items: left;
  margin-top: 16px;
  max-width: ${props => props.maxWidth};
  ul {
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    margin-bottom: 0;
  }
`;

type StandalonAppFrameProps = {
  title: string;
  children?: ReactNode;
};

const StandaloneAppFrame = (props: StandalonAppFrameProps) => {
  const wrapperGoogleConnectRef = useRef<HTMLDivElement>(null);
  const [wrapperGoogleConnectWidth, setWrapperGoogleConnectWidth] = useState<string>();

  useEffect(() => {
    setWrapperGoogleConnectWidth(
      `${wrapperGoogleConnectRef?.current?.getBoundingClientRect().width}px`
    );
  }, []);

  return (
    <StandaloneAppFrameStyle>
      <WrapperGoogleConnect ref={wrapperGoogleConnectRef}>
        {props.title && <Title>{props.title}</Title>}
        {props.children}
      </WrapperGoogleConnect>
      {appConfig.IS_XIMI_MIGRATION_WARNING && wrapperGoogleConnectWidth && (
        <XimiMigrationWarning maxWidth={wrapperGoogleConnectWidth}>
          <XimiMigrationWarningAtConnection />
        </XimiMigrationWarning>
      )}
    </StandaloneAppFrameStyle>
  );
};

export const The404 = () => (
  <StandaloneAppFrame title={"Ooops, page non trouvé..."}>
    <TextLogin>
      À transmettre à la tech' <TextLoginHighlight>{window.location.pathname}</TextLoginHighlight>
    </TextLogin>
    <TextLogin style={{ marginTop: "16px" }}>
      <a href="/">Retour à l'application</a>
    </TextLogin>
  </StandaloneAppFrame>
);

export const UnsupportedBrowser = () => (
  <StandaloneAppFrame title={"Votre navigateur n'est pas pris en charge !"}>
    <TextLogin>Rapprochez vous de l'équipe tech pour plus d'informations</TextLogin>
  </StandaloneAppFrame>
);

export const OldChromeBrowser = ({ browserOs }: { browserOs: OperatingSystem }) => {
  const text = "Mettez à jour votre version de Chrome pour accéder à Hassibot.";
  return (
    <StandaloneAppFrame title={"Votre navigateur est trop ancien !"}>
      <TextLogin>
        {browserOs === "Android OS" ? (
          <a href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=fr">
            {text}
          </a>
        ) : (
          text
        )}
      </TextLogin>
    </StandaloneAppFrame>
  );
};

export const OldSafariBrowser = ({ browserOs }: { browserOs: OperatingSystem }) => {
  let toUpdate = "de Safari";
  if (browserOs === "iOS") {
    toUpdate = "d'iOS";
  } else if (browserOs === "Mac OS") {
    toUpdate = "de macOS";
  }
  return (
    <StandaloneAppFrame title={"Votre navigateur est trop ancien !"}>
      <TextLogin>Mettez à jour votre version {toUpdate} pour accéder à Hassibot.</TextLogin>
    </StandaloneAppFrame>
  );
};

export const WrongBrowserOnMobile = ({
  infos,
  platform,
}: {
  platform: OperatingSystem;
  infos: BrowserInfo | BotInfo | NodeInfo | null;
}) => (
  <StandaloneAppFrame title={"Mauvais navigateur !"}>
    {platform === "Android OS" ? (
      <TextLogin>
        Utilisez{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.android.chrome"
        >
          Google Chrome
        </a>{" "}
        pour accéder à Hassibot sur Android.
      </TextLogin>
    ) : (
      <TextLogin>
        Utilisez{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://www.apple.com/fr/safari/">
          Safari
        </a>{" "}
        pour accéder à Hassibot sur iOS.
      </TextLogin>
    )}
    <details style={{ opacity: 0.2 }}>{JSON.stringify(infos)}</details>
  </StandaloneAppFrame>
);

export const BadRequest = () => (
  <StandaloneAppFrame title={"Ooops, mauvaise URL"}>
    <TextLogin>
      Pas besoin de transmettre à la tech, l'URL saisie n'est pas correcte <Emoji name="bomb" />.
    </TextLogin>
  </StandaloneAppFrame>
);

export const HoustonWeveGotAProblem = () => (
  <StandaloneAppFrame title={"Ooops, on a eu un problème..."}>
    <TextLogin>
      À transmettre à la tech' <TextLoginHighlight>{window.location.pathname}</TextLoginHighlight>.
    </TextLogin>
  </StandaloneAppFrame>
);

export const LoginError = ({ errorMessage }) => (
  <StandaloneAppFrame title={"Problème de connexion"}>
    <TextLogin>Veuillez transmettre ce message à la tech': {errorMessage}</TextLogin>
  </StandaloneAppFrame>
);

export const Loading = () => <StandaloneAppFrame title={"Chargement en cours..."} />;

export const LoginStandaloneApp = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    const listener = (e: any) => {
      // Not the message we're expecting
      if (!e.data.NIGOL_GOOGLE_JWT) {
        return;
      }

      const decoded = jwtDecode(e.data.NIGOL_GOOGLE_JWT);
      digestGoogleAuthLogin({
        mail: decoded.email,
        googleId: decoded.sub,
        googleJwt: e.data.NIGOL_GOOGLE_JWT,
      }).then(response => {
        if (response instanceof Error) {
          const error = messageFor()(response.message);
          setErrorMessage(error);
        }
      });
    };

    window.addEventListener("message", listener);
    return () => window.removeEventListener("message", listener);
  }, []);

  const modalWidth = Math.min(599, window.screen.width - 20),
    modalHeight = Math.min(600, window.screen.height - 30);

  const windowFeatures = `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,width=${modalWidth},height=${modalHeight},top=${
    (window.screen.height - modalHeight) / 2
  },left=${(window.screen.width - modalWidth) / 2}`;
  const originUrl = btoa(encodeURIComponent(window.location.href));

  if (errorMessage) return <LoginError errorMessage={errorMessage} />;

  return (
    <>
      <StandaloneAppFrame title={"Connexion à Hassibot"}>
        <ButtonWrapper align="center">
          <Button
            style={{ width: "100%", justifyContent: "center" }}
            type="highlight"
            size="lg"
            onClick={() => {
              window.open(
                `https://nigol.ouihelp.fr?originUrl=${originUrl}`,
                "LOGIN_WINDOW",
                windowFeatures
              );
            }}
          >
            Se connecter
          </Button>
        </ButtonWrapper>
      </StandaloneAppFrame>
    </>
  );
};
