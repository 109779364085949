import { Store } from "idb-keyval";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";

// A simple addition to `idb-keyval`
export const getAll = <T>(store: Store): Promise<T[]> => {
  let req: IDBRequest<T[]>;
  return store
    ._withIDBStore("readonly", store => {
      req = store.getAll();
    })
    .then(() => req.result);
};

/**
 * Custom idb-keyval store to enable multiple stores per IndexedDB
 * database and auto-reconnect when DB has closed.
 *
 * Slightly different solution than
 * https://github.com/jakearchibald/idb-keyval/pull/50 for the
 * auto-reconnect feature.
 */
export class CustomStore {
  readonly _dbName: string;
  readonly _storeName: string;
  readonly _knownEntityStores: string[];
  _openedDb: Promise<IDBDatabase> | null;

  // Type compat with idb-keyval `Store`
  readonly storeName: string = "UNUSED";
  readonly _dbp: Promise<IDBDatabase> = new Promise(() => {});

  constructor(dbName: string, storeName: string, knownEntityStores: string[]) {
    this._dbName = dbName;
    this._storeName = storeName;
    this._knownEntityStores = knownEntityStores;
    this._openedDb = null;
  }

  _makeDbPromise(): Promise<IDBDatabase> {
    if (this._openedDb === null) {
      this._openedDb = new Promise<IDBDatabase>((resolve, reject) => {
        const openreq = indexedDB.open(this._dbName);
        openreq.onerror = () => reject(openreq.error);
        openreq.onsuccess = () => resolve(openreq.result);
      }).then(openreqResult => {
        const currentStoreNames = new Set(Array.from(openreqResult.objectStoreNames));
        const wantedStoreNames = new Set(this._knownEntityStores);

        // That's the main part that allows us to have multiple store per
        // database.
        // While opening the database we compare the stores we have
        // inside with the stores we want inside an create/drop the
        // diff.
        if (!isEqual(wantedStoreNames, currentStoreNames)) {
          // We will upgrade our DB to this "next version"
          const nextVersion = openreqResult.version + 1;

          // We need to reopen the database with `nextVersion` in order
          // to hook ourselves in `onupgradeneeded`.
          openreqResult.close();

          return new Promise<IDBDatabase>((resolveNewDbp, rejectNewDbp) => {
            const newOpenreq = indexedDB.open(this._dbName, nextVersion);
            newOpenreq.onerror = () => rejectNewDbp(newOpenreq.error);
            newOpenreq.onsuccess = () => {
              // When the connection closes, we remove our ref to
              // `this._openDb`.
              newOpenreq.result.onclose = () => {
                this._openedDb = null;
              };
              resolveNewDbp(newOpenreq.result);
            };

            newOpenreq.onupgradeneeded = () => {
              difference(Array.from(currentStoreNames), Array.from(wantedStoreNames)).forEach(
                name => newOpenreq.result.deleteObjectStore(name)
              );
              difference(Array.from(wantedStoreNames), Array.from(currentStoreNames)).forEach(
                name => newOpenreq.result.createObjectStore(name)
              );
            };
          });
        }

        // Same as before, when the connection closes, we remove our
        // ref to `this._openedDb`.
        openreqResult.onclose = () => {
          this._openedDb = null;
        };
        return openreqResult;
      });
    }

    return this._openedDb;
  }

  _withIDBStore(
    type: IDBTransactionMode,
    callback: (store: IDBObjectStore) => void
  ): Promise<void> {
    return this._makeDbPromise().then(
      db =>
        new Promise<void>((resolve, reject) => {
          const transaction = db.transaction(this._storeName, type);
          transaction.oncomplete = () => resolve();
          transaction.onabort = transaction.onerror = () => reject(transaction.error);
          callback(transaction.objectStore(this._storeName));
        })
    );
  }
}

export * from "idb-keyval";
