import { QueryClient } from "@tanstack/react-query";

export const hassibotQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // prevent refetch on window / tab refocus
      refetchOnWindowFocus: false,
      // prevent retry if a request fail
      retry: false,
      // remove query data from catch once a hook is unmount
      // keep react-query behavior iso to hb current expected behavior
      cacheTime: 0,
    },
  },
});
