import React from "react";
import styled from "styled-components";
import { switchProp } from "styled-tools";
import { color, spaces } from "emoreg/const";

const RawInputSubtext = styled.span`
  display: block;
  font-size: 12px;
  margin-top: ${spaces[4]};

  ${switchProp("type", {
    error: `color: ${color.red.base};`,
    warn: `color: ${color.orange.base};`,
    helper: `color: ${color.grey.dark}; font-style: italic;`,
  })}
`;

const InputSubtext = ({ type, style, children }) => (
  <RawInputSubtext type={type} style={style}>
    {children ? children : "Ce champ est invalide"}
  </RawInputSubtext>
);

export default InputSubtext;
