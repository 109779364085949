import { ApiResponse } from "./types";
import { handleResponsePromise } from "./internal/helpers";

export const fakeFetchResponse = (
  statusCode: number,
  responseBody?: any,
  responseHeaders?: Record<string, string>
): Response =>
  new Response(responseBody ? JSON.stringify(responseBody) : null, {
    status: statusCode,
    headers: new Headers(responseHeaders || {}),
  });

export const fakeApiResponse = <T>(
  statusCode: number,
  responseBody?: any,
  responseHeaders?: Record<string, string>
): Promise<ApiResponse<T>> =>
  handleResponsePromise<T>(
    // For now the "fake request" is totally empty, at some point we
    // might want to pass fake URL & method as arguments to
    // `fakeApiResponse`.
    new Request(""),
    Promise.resolve(fakeFetchResponse(statusCode, responseBody, responseHeaders))
  );
