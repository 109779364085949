import { detect, OperatingSystem } from "detect-browser";
import React from "react";

import * as Sentry from "@sentry/react";

import "custom-pwa-compat";

import appConfig from "hassibot/app-config";

import { mtLogger } from "shared-core";
import { useFollowServiceWorker } from "sw-registration";

import { uuidv4 } from "hassibot/util/uuid";
import { SessionContainer } from "./inside-components";
import {
  BadRequest,
  HoustonWeveGotAProblem,
  Loading,
  LoginStandaloneApp,
  OldChromeBrowser,
  OldSafariBrowser,
  The404,
  UnsupportedBrowser,
  WrongBrowserOnMobile,
} from "./outside-components";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { initAmplitude } from "amplitude";
import { MaintenancePage } from "hassibot/component/maintenance-mode/MaintenancePage";
import { hassibotQueryClient } from "hassibot/react-query/query-client";
import { DeviceId } from "hassibot/services_v2/common/types";
import "scss/main.scss";
import { HassibotError } from "shared-core/custom-error";
import { useUserCredentialsSubject } from "./token-manager";
import { CurrentLegalEntityProvider } from "hassibot/current-legal-entity-provider";

const lsUserPrefix = "USER_STATE";

mtLogger.log(`Welcome to Hassibot! I'm ${appConfig.BUILD_HASH}`);

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    ...(appConfig.BUILD_HASH && { release: appConfig.BUILD_HASH }),
    ...(appConfig.IS_TEST_ENVS && { environment: "test-envs" }),
    ignoreErrors: ["Non-Error promise rejection captured with value: [Screeb] 'init'"],
  });
}

const ensureDeviceId = (): DeviceId => {
  // get deviceID into the localStorage
  const deviceId = localStorage.getItem(`${lsUserPrefix}.DEVICE_ID`);
  // if DEVICE_ID is null generate uuid and put it into local storage
  if (deviceId === null) {
    const newDeviceId = uuidv4(true);
    localStorage.setItem(`${lsUserPrefix}.DEVICE_ID`, newDeviceId);
    appConfig.DEVICE_ID = newDeviceId as DeviceId;
    return newDeviceId;
  } else {
    appConfig.DEVICE_ID = deviceId;
    return deviceId;
  }
};
const deviceId = ensureDeviceId();

initAmplitude(deviceId);

// Start our shared worker early to give it a head start. Beware, as of
// 2023-11-16 this does not work an Android (see following refs). We
// fallback to "regular worker" there, with an abvious performance
// cost. Ref:
//   - https://caniuse.com/sharedworkers
//   - https://bugs.chromium.org/p/chromium/issues/detail?id=154571
window.SharedWorker &&
  new SharedWorker(new URL("./alternative-entrypoints/webworker", import.meta.url), {
    type: "module",
  });

type InsideContainerState = {
  errorType?: number;
};

class InsideContainer extends React.PureComponent<{}, InsideContainerState> {
  /**
   * This container is the first one that is rendered in a context where we when know that we are logged with an active token
   * It render Hassibot and handle fatal errors.
   */
  constructor(props) {
    super(props);
    this.state = { errorType: undefined };
  }

  componentDidCatch(error, errorInfo) {
    const errorType = error.type || 520;
    this.setState({ errorType });

    // Do not trigger Sentry for a 400 & 404
    if ([400, 404].includes(errorType)) {
      return;
    }

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      if (error instanceof HassibotError) {
        if (error.additionalData) {
          const additionalData = error.additionalData;
          Object.keys(additionalData).forEach(key => {
            scope.setExtra(key, additionalData[key]);
          });
        }
      }

      Sentry.captureException(error);
    });
  }

  errorTypeToComponent = (errorType?: number) => {
    switch (true) {
      case !errorType:
        return null;
      case errorType === 400:
        return BadRequest;
      case errorType === 404:
        return The404;
      default:
        return HoustonWeveGotAProblem;
    }
  };

  render() {
    const { errorType } = this.state;

    const ErrorComponent = this.errorTypeToComponent(errorType);
    // Error boundaries
    if (ErrorComponent) {
      return <ErrorComponent />;
    }

    const ShellContainer = React.lazy(() => import("./hassibot/main"));
    const SetFirebase = React.lazy(() => import("./hassibot/firebase"));
    const GlobalStyle = React.lazy(() => import("./styles"));
    return (
      <React.Suspense fallback={<Loading />}>
        <SessionContainer>
          <CurrentLegalEntityProvider>
            <GlobalStyle />
            <SetFirebase />
            <ShellContainer />
          </CurrentLegalEntityProvider>
        </SessionContainer>
      </React.Suspense>
    );
  }
}

/*
 * Read on IndexedDB and listen thought BroadcastChannel for token related purposes.
 * Load Hassibot or Login view depending on the auth token state
 */
const UserStateContainer = () => {
  const userCreds = useUserCredentialsSubject();
  useFollowServiceWorker();

  // While IndexedDB is fetching, prompt a spinner
  if (userCreds === undefined) {
    return <Loading />;
  }

  // If IndexedDB is done fetching but there is no credentials, trigger login process
  else if (userCreds === null) {
    return <LoginStandaloneApp />;
  }

  // If credentials are available, load the app,
  return <InsideContainer />;
};

const biggerThan92DotX = (version: string) => {
  const [majorVersion, _minorVersion, _patchVersion] = version.split(".");
  if (!majorVersion) {
    throw new Error(`Invalid version: major=${majorVersion}`);
  }
  return parseInt(majorVersion) >= 92;
};

const biggerThan15Dot4 = (version: string) => {
  const [majorVersion, minorVersion] = version.split(".");
  if (!majorVersion || !minorVersion) {
    throw new Error(`Invalid version: major=${majorVersion} minor=${minorVersion}`);
  }
  return (
    parseInt(majorVersion) > 15 || (parseInt(majorVersion) === 15 && parseInt(minorVersion) >= 4)
  );
};

// Check if Hassibot can run in the current browser.
const TestBrowserContainer = () => {
  const browserInfos = detect();
  const browser =
    browserInfos && browserInfos.name && browserInfos.version && browserInfos.os
      ? {
          name: browserInfos.name,
          version: browserInfos.version,
          os: browserInfos.os as OperatingSystem,
        }
      : undefined;

  const browserIsChrome = browser ? browser.name === "chrome" : undefined;
  const isOldChromeBrowser = browser
    ? browserIsChrome && !biggerThan92DotX(browser.version)
    : undefined;

  const browserIsSafari = browser ? browser.name === "safari" || browser.name === "ios" : undefined;
  const isOldSafariBrowser = browser
    ? browserIsSafari && !biggerThan15Dot4(browser.version)
    : undefined;

  const isWrongBrowserOnMobile = browser
    ? (browser.os === "Android OS" && browser.name !== "chrome") ||
      (browser.os === "iOS" && !browserIsSafari)
    : undefined;
  if (browser && isWrongBrowserOnMobile) {
    return <WrongBrowserOnMobile infos={browserInfos} platform={browser.os} />;
  } else if (browser && isOldChromeBrowser) {
    return <OldChromeBrowser browserOs={browser.os} />;
  } else if (browser && isOldSafariBrowser) {
    return <OldSafariBrowser browserOs={browser.os} />;
  } else if (
    !window.WebSocket ||
    !navigator.serviceWorker ||
    !Object.fromEntries ||
    !window.BroadcastChannel
  ) {
    return <UnsupportedBrowser />;
  } else {
    return <UserStateContainer />;
  }
};

// Welcome to Hassibot!
export const Hassibot = () => {
  if (appConfig.IS_MAINTENANCE_MODE) {
    return <MaintenancePage />;
  }

  return (
    <QueryClientProvider client={hassibotQueryClient}>
      <TestBrowserContainer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
