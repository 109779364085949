import { JsonValueCompatible } from "siayuda/types";

export class HassibotError<T> extends Error {
  additionalData?: JsonValueCompatible<T>;

  constructor(message?: string, additionalData?: JsonValueCompatible<T>) {
    super(message);
    this.additionalData = additionalData;
  }
}
