import { useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import styled from "styled-components";

const EmojiStyle = styled.span``;

class EmojiData {
  robot = {
    value: "🤖",
    label: "Robot",
    labelledby: "Robot",
  };
  note = {
    value: "🗓",
    label: "Calepin",
    labelledby: "Calepin",
  };
  letterBox = {
    value: "📬",
    label: "Boîte aux lettres",
    labelledby: "Boîte aux lettres",
  };
  startingFlag = {
    value: "🏁",
    label: "Drapeau de départ",
    labelledby: "Drapeau de départ",
  };
  girlHandUp = {
    value: "🙋‍",
    label: "Femme main en l'air",
    labelledby: "Femme main en l'air",
  };
  bomb = {
    value: "💣",
    label: "Booom!",
    labelledby: "Booom!",
  };
  unhappy = {
    value: "🙁",
    label: "Léger froncement de sourcils",
    labelledby: "Léger froncement de sourcils",
  };
  wink = {
    value: "😉",
    label: "clin d'œil",
    labelledby: "clin d'œil",
  };
  ok = {
    value: "👌",
    label: "Ok",
    labelledby: "Ok",
  };
  pleurs = {
    value: "😢",
    label: "Pleurs",
    labelledby: "Pleurs",
  };
  plus1 = {
    value: "👍🏼",
    label: "+1",
    labelledby: "+1",
  };
  handup = {
    value: "🙋‍",
    label: "bras levé",
    labelledby: "Main en l'air",
  };
  hurted = {
    value: "🤕",
    label: "mal à la tête",
    labelledby: "mal à la tête",
  };
  blueHeart = {
    value: "💙",
    label: "coeur bleu",
    labelledby: "coeur bleu",
  };
  greenHeart = {
    value: "💚",
    label: "coeur vert",
    labelledby: "coeur vert",
  };
  redHeart = {
    value: "❤️",
    label: "coeur rouge",
    labelledby: "coeur rouge",
  };
  birthcake = {
    value: "🎂",
    label: "gâteau d'anniversaire",
    labelledby: "gâteau d'anniversaire",
  };
  muscle = {
    value: "💪",
    label: "muscle",
    labelledby: "muscle",
  };
  heartEyes = {
    value: "😍",
    label: "heart eyes",
    labelledby: "coeur dans les yeux",
  };
  tada = {
    value: "🎉",
    label: "tada",
    labelledby: "tada",
  };
  warning = {
    value: "⚠️",
    label: "warning",
    labelledby: "warning",
  };
  infos = {
    value: "ℹ️",
    label: "infos",
    labelledby: "infos",
  };
  medal = {
    value: "🏅",
    label: "médaille",
    labelledby: "médaille",
  };
}
type EmojiDataType = Record<keyof EmojiData, EmojiDataValueType>;
type EmojiDataValueType = {
  value: string;
  label: string;
  labelledby: string;
};
const emojiDataInstance: EmojiDataType = new EmojiData();

type EmojiProps = {
  name: keyof EmojiData;
  style?: React.CSSProperties;
  onClick?: () => void;
};
export const Emoji = ({ name, style, onClick }: EmojiProps) => (
  <EmojiStyle
    role="img"
    aria-label={emojiDataInstance[name].label}
    aria-labelledby={emojiDataInstance[name].labelledby}
    style={style}
    onClick={onClick}
  >
    {emojiDataInstance[name].value}
  </EmojiStyle>
);

const explosion = {
  force: 0.6,
  duration: 2500,
  particleCount: 80,
  width: 1000,
};

export const ExplodingTada = () => {
  const [isExploding, setIsExploding] = useState(false);

  return (
    <>
      <span style={{ position: "absolute" }}>
        {isExploding && (
          <ConfettiExplosion {...explosion} onComplete={() => setIsExploding(false)} />
        )}
      </span>
      <Emoji
        name="tada"
        onClick={() => setIsExploding(true)}
        style={{ cursor: isExploding ? "default" : "pointer" }}
      />
    </>
  );
};
