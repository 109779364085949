export type ApiResponse<T> = ApiSuccess<T> | EmptySuccess | Failure;

/**
 * This is the response we expect when we call our API: a success!
 */
export type ApiSuccess<T> = {
  kind: "SUCCESS";
  value: T;
  statusCode: number;
  headers: Headers;
  pager?: Pager;
  request: Request;
};

/**
 * This is, as the names states, a successful response which does not
 * carry a value.
 */
export type EmptySuccess = ApiSuccess<undefined>;

/**
 * This error is used for a successful response (`fetch`-wise) which is
 * carrying a "global error", meaning not strictly related to one value
 * given in the request, from the API.
 */
export type GlobalError = {
  kind: "GLOBAL_ERROR";
  statusCode: number;
  error: string;
  extra?: Record<string, any>;
  headers: Headers;
  request: Request;
};

export type FieldErrorWithExtra = {
  type: string;
  extra: Record<string, any>;
};
export type FieldErrorsWithExtra = { [key: string]: FieldErrorWithExtra[] };
/** @deprecated Use `FieldErrorsWithExtra` instead.*/
export type FieldErrors = { [key: string]: string[] };

/**
 * This error is used for a successful response (`fetch`-wise) which is
 * carrying a list of field errors related to the field given in the
 * outgoing request to the API.
 */
export type ClientError = {
  kind: "CLIENT_ERROR";
  statusCode: number;
  /** @deprecated Use `errorWithExtra` instead.*/
  error: FieldErrors;
  errorWithExtra: FieldErrorsWithExtra;
  headers: Headers;
  request: Request;
};

/**
 * This error is used for a timed out request, meaning that the request
 * has expired the duration stated client-side (in a `timeoutMs`
 * argument).
 */
export type TimeoutError = {
  kind: "TIMEOUT_ERROR";
  error: "TIMEOUT_ERROR";
  request: Request;
  timeoutMs: number;
};

/**
 * This error is used for a successful response (`fetch`-wise) whose
 * HTTP status code is above (or equal to) 400 that and which is not
 * recoverable, meaning:
 *    - Reytring the request won't result in a successful response most
 *      of the time (it could, if we have a 500 for instance)
 *    AND
 *    - A change in the request's body has no impact in the success of
 *      the request (otherwise it's a `ClientError` or a `GlobalError`)
 *
 * *For now* and for backward compatibility the 401 and 503 are both
 * considered unrecoverable although they clearly are not.
 */
export type UnrecoverableHttpError = {
  kind: "UNRECOVERABLE_HTTP_ERROR";
  statusCode: number;
  headers: Headers;
  error: { error?: string };
  request: Request;
};

/**
 * This error is used for a failed `fetch` attempt. Such a failure can
 * come from many sources, some examples:
 *    - A network failure during the request (DNS timeout, lost network
 *      during the request, ...)
 *    - A network failure during the response (lost network before the
 *      first response byte)
 *    - A CORS failure (which is how a network failure during the
 *      request will manifest itself most of the time)
 *
 * Beware, a network failure after the response has started streaming
 * will not result in the `fetch` returned promise being rejected but
 * with an empty body.
 *
 * Most of the `FetchError` *are* retryable with a good probability of
 * success, except for the hard to detect once in prod:
 *    - CORS misconfiguration
 *    - Bad URL (ending up in a DNS timeout, CORS error, ...)
 *
 * More info: https://medium.com/to-err-is-aaron/detect-network-failures-when-using-fetch-40a53d56e36
 */
export type FetchError = {
  kind: "FETCH_ERROR";
  error: Error;
  request: Request;
};

export type Failure =
  | GlobalError
  | ClientError
  | TimeoutError
  | UnrecoverableHttpError
  | FetchError;

// See Flavor typing https://spin.atomicobject.com/2018/01/15/typescript-flexible-nominal-typing/
interface Typing<T> {
  _type?: T;
}
type TypeBuilder<U, T> = U & Typing<T>;
type UuidTypeBuilder<T> = TypeBuilder<string, T>;
export type ProclientUuid = UuidTypeBuilder<"proclient">;
export type CaregiverUuid = UuidTypeBuilder<"caregiver">;
export type ContactUuid = UuidTypeBuilder<"contact">;
export type XimiId = UuidTypeBuilder<"ximi_id">;
export type InterventionId = UuidTypeBuilder<"intervention">;

const isNumber = <T>(n: T | number): n is number => typeof n === "number";
const or = <T, U>(predicate: (i: T) => boolean, value: T, defaultValue: U): T | U =>
  predicate(value) ? value : defaultValue;

export class Pager {
  constructor(
    public size: number = 10,
    public page: number = 1,
    public total: number | null = null,
    public pages: number | null = null,
    public aggregates: Record<string, number> | null = null
  ) {}

  static fromQueryString = (
    qs: string,
    aggregates: Record<string, number> | null = null
  ): Pager | null => {
    const sp = new URLSearchParams(qs),
      s = sp.get("size"),
      p = sp.get("page"),
      tp = sp.get("pages"),
      ti = sp.get("total");

    return new Pager(
      s ? parseInt(s) : 10,
      p ? parseInt(p) : 0,
      ti ? parseInt(ti) : null,
      tp ? parseInt(tp) : null,
      aggregates
    );
  };

  clone = (partial: Partial<Pager>) =>
    new Pager(
      or(isNumber, partial.size, this.size),
      or(isNumber, partial.page, this.page),
      or(isNumber, partial.total, this.total),
      or(isNumber, partial.pages, this.pages)
    );
}

type AuthInitType = "DACA" | "HB" | "NOSILA";

export class AuthConfig {
  typeToHeaderString: { [key in AuthInitType]: (token: string) => string } = {
    DACA: (token: string) => `Bearer-CG ${token}`,
    HB: (token: string) => `Bearer-OH-DB ${token}`,
    NOSILA: (token: string) => `Bearer-LP ${token}`,
  };
  constructor(public type: AuthInitType, public token: string) {}

  clone = (partial: Partial<AuthConfig>) =>
    new AuthConfig(partial.type || this.type, partial.token || this.token);

  makeAuthHeader() {
    return { Authorization: this.typeToHeaderString[this.type](this.token) };
  }
}

export type ResponseHook = ((response: Response) => void) | ((response: Response) => Promise<void>);

export type ApiV2ClientConfig = {
  auth: AuthConfig | null;
  defaultTimeoutMs?: number;
  preProcessingResponseHooks?: ResponseHook[];
};

export type Reader<I, T> = (input: I) => T;
