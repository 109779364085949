import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { switchProp } from "styled-tools";

import { font } from "styles/const";
import { color, spaces } from "emoreg/const";

type TitleProps = {
  type: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  style?: {};
  color?: "black" | "normal" | "white" | "highlight";
  className?: string;
};

const titleDefaultProps: TitleProps = {
  type: "h1",
  color: "normal",
};

/**
 * @deprecated
 */
export const Title = styled(({ type, ...props }: PropsWithChildren<TitleProps>) => {
  return React.createElement(type, props);
})`
  font-weight: ${font.weight.bold};
  ${switchProp("type", {
    h1: css`
      font-size: ${rem(32)};
    `,
    h2: css`
      font-size: ${rem(24)};
      margin-bottom: ${spaces[24]};
    `,
    h3: css`
      font-size: ${rem(20)};
      margin-bottom: ${spaces[4]};
    `,
    h4: css`
      font-size: ${rem(18)};
      margin-bottom: ${spaces[4]};
    `,
    h5: css`
      font-size: ${rem(16)};
      line-height: 1.45;
    `,
  })};

  color: ${switchProp("color", {
    highlight: color.blue.base,
    black: color.grey.darker,
    normal: color.grey.dark,
    white: "#fff",
  })};
`;

Title.defaultProps = titleDefaultProps;
