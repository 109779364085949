import styled from "styled-components";

import { Title } from "emoreg/atoms/Title";
import { Typography } from "emoreg/atoms/Typography";
import { VerticalStack } from "emoreg/layouts/VerticalStack";
import { OuihelpColorLogo } from "./styles";

const FrameStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const WrapperText = styled.div`
  max-width: 440px;
  margin-top: 24px;
`;

export const MaintenancePage = () => {
  return (
    <FrameStyle>
      <OuihelpColorLogo />
      <WrapperText>
        <VerticalStack gap={24}>
          <Title type="h4">Maintenance en cours</Title>
          <Typography>
            Ximi déplace nos données vers un nouveau serveur, plus rapide. Hassibot sera
            indisponible le temps de la migration (~2 heures).
            <br />
            <br />
            L’annonce de remise en ligne sera faite sur Slack, sur #general
          </Typography>
        </VerticalStack>
      </WrapperText>
    </FrameStyle>
  );
};
