import {
  ApiResponse,
  ApiSuccess,
  ClientError,
  EmptySuccess,
  Failure,
  GlobalError,
  TimeoutError,
  UnrecoverableHttpError,
  FetchError,
} from "./types";

export const isEmpty = <T>(x: ApiResponse<T>): x is EmptySuccess =>
  x.kind === "SUCCESS" && x.value === undefined;
export const isSuccess = <T>(x: ApiResponse<T>): x is ApiSuccess<T> =>
  x.kind === "SUCCESS" && x.value !== undefined;
export const isFailure = <T>(x: ApiResponse<T>): x is Failure => x.kind && x.kind.endsWith("ERROR");

export const isClientError = <T>(x: ApiResponse<T>): x is ClientError =>
  isFailure(x) && x.kind === "CLIENT_ERROR";
export const isGlobalError = <T>(x: ApiResponse<T>): x is GlobalError =>
  isFailure(x) && x.kind === "GLOBAL_ERROR";
export const isTimeoutError = <T>(x: ApiResponse<T>): x is TimeoutError =>
  isFailure(x) && x.kind === "TIMEOUT_ERROR";

export const isFetchError = <T>(x: ApiResponse<T>): x is FetchError =>
  isFailure(x) && x.kind === "FETCH_ERROR";
export const isUnrecoverableHttpError = <T>(x: ApiResponse<T>): x is UnrecoverableHttpError =>
  isFailure(x) && x.kind === "UNRECOVERABLE_HTTP_ERROR";

/**
 * For backward compatible reasons a `fetch` error is considered
 * "unrecoverable".
 */
export const isUnrecoverableError = <T>(
  x: ApiResponse<T>
): x is UnrecoverableHttpError | FetchError => isFetchError(x) || isUnrecoverableHttpError(x);
