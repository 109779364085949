import { collaboratorReader } from "hassibot/services_v2/collaborator/reader";
import { LegalMode, JsonValue, LegalEntity } from "hassibot/services_v2/common/types";
import { AuthToken, SessionCollaborator } from "hassibot/services_v2/login/types";
import { list } from "hassibot/services_v2/common/reader";

/** Temp implem to update the LegalMode enum value without breaking HB
 * Should be kept for the collaborators that have not logout since Sep 8th 2024
 */
export enum DeprecatedLegalMode {
  // Deprecated values
  PRESTA = "presta",
  MANDAT = "mandat",
  // new values
  CONTRACTOR = LegalMode.CONTRACTOR,
  MANDATARY = LegalMode.MANDATARY,
}
// deprecated -> new values mapping
export const DEPRECATED_TO_LEGAL_MODE: Record<DeprecatedLegalMode, LegalMode> = {
  [DeprecatedLegalMode.PRESTA]: LegalMode.CONTRACTOR,
  [DeprecatedLegalMode.MANDAT]: LegalMode.MANDATARY,
  [DeprecatedLegalMode.MANDATARY]: LegalMode.MANDATARY,
  [DeprecatedLegalMode.CONTRACTOR]: LegalMode.CONTRACTOR,
};
const legalEntityReader = (json: JsonValue): LegalEntity => ({
  uuid: json.uuid,
  name: json.name,
  mode: DEPRECATED_TO_LEGAL_MODE[json.mode],
});

const legalEntitiesReader = list(legalEntityReader);
/** End of temp implem */

export const sessionCollaboratorReader = (json: JsonValue): SessionCollaborator => ({
  ...collaboratorReader(json),
  deviceId: json.deviceId,
  validPushToken: json.validPushToken,
  permerUserAttributes: json.permerUserAttributes,
  // myAccessibleLegalEntities may be empty for collaborators that have not logout since July 8th 2024
  myAccessibleLegalEntities: json.myAccessibleLegalEntities
    ? legalEntitiesReader(json.myAccessibleLegalEntities)
    : [],
});

export const loginReader = (json: JsonValue): AuthToken => ({
  authToken: json.authToken,
});
