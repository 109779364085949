import React, { PropsWithChildren, useMemo } from "react";
import styled, { css } from "styled-components";

import { ResponsiveProps } from "emoreg/types";
import { naiveToResponsiveProp } from "emoreg/utils/responsive-props";
import { forTabletUp } from "emoreg/utils/responsive";
import { color, patchRemResponsiveProps, Spaces } from "emoreg/const";

type VerticalStackStyleProps = {
  gap: ResponsiveProps<string>;
  justify?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
};

const VerticalStackStyle = styled.div`
  display: flex;
  flex-direction: column;

  ${(props: VerticalStackStyleProps) => css`
    gap: ${props.gap.mobile};
    ${forTabletUp`
        gap: ${props.gap.tablet};
      `}
  `}

  align-items: ${({ alignItems = "normal" }: VerticalStackStyleProps) => alignItems};
  justify-content: ${({ justify = "normal" }: VerticalStackStyleProps) => justify};
`;
VerticalStackStyle.displayName = "VerticalStackStyle";

type VerticalStackProps = {
  gap: ResponsiveProps<Spaces> | Spaces;
  hasDivider?: boolean;
  childWrapperComponent?: React.ComponentType<PropsWithChildren<{}>>;
  className?: string;
  style?: React.CSSProperties;
  justify?: React.CSSProperties["justifyContent"];
  alignItems?: React.CSSProperties["alignItems"];
};

interface DividerProps {
  orientation?: "horizontal" | "vertical";
}
export const Divider = ({ orientation = "horizontal" }: DividerProps) => {
  const borderKey = orientation === "horizontal" ? "borderTop" : "borderLeft";

  return (
    <div
      style={{
        margin: "0",
        borderColor: color.grey.base,
        alignSelf: "stretch",
        [borderKey]: `0.5px solid ${color.grey.base}`,
      }}
    />
  );
};

/**
 * Vertically stack an arbitrary amount of elements.
 *
 * @param gap - The gap between two successive elements. Must be an
 * entry of sizes listed here `/src/emoreg/const.ts`.
 *
 * @param hasDivider - Wether two successive elements should be
 * separated by a divider.
 *
 * @param childWrapperComponent - An optional component to wrap each
 * individual element with (eg: to add padding).
 *
 * **Beware**, as a performance optimization, only the first value of
 * this prop (the one passed to the first mount/render of
 * `<VerticalStack />`) will be registered and used for all subsequent
 * renders.
 *
 * **Beware 2**, this component has rough edge cases when trying to use
 * dividers (`hasDivider`) with "possibly null children" or with a
 * `childWrapperComponent`. See `<ContentVerticalStackWithDivider />`
 * for a different approach, with different tradeoffs.
 */
export const VerticalStack = (props: PropsWithChildren<VerticalStackProps>) => {
  const DefaultChildWrapper = useMemo(
    () => {
      if (props.childWrapperComponent) {
        return props.childWrapperComponent;
      }
      const rv = ({ children }) => <>{children}</>;
      rv.displayName = "VerticalStackChildWrapperComponent";
      return rv;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <VerticalStackStyle
      style={props.style}
      gap={naiveToResponsiveProp(patchRemResponsiveProps(props.gap))}
      alignItems={props.alignItems}
      justify={props.justify}
      className={props.className}
    >
      {/*Children toArray auto filters boolean, null and undefined*/}
      {React.Children.toArray(props.children).map((child, i) => {
        return (
          <React.Fragment key={i}>
            {props.hasDivider && i !== 0 && <Divider />}
            <DefaultChildWrapper>{child}</DefaultChildWrapper>
          </React.Fragment>
        );
      })}
    </VerticalStackStyle>
  );
};
