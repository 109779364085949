import { ASK_SW_BUILD_HASH, mtLogger } from "shared-core";

import appConfig from "hassibot/app-config";

const isProduction = import.meta.env.MODE === "production";
const enableSW = isProduction || import.meta.env.VITE_DEBUG_SERVICE_WORKER === "true";

// Register the service worker and store its registration
export const serviceWorkerRegistration: Promise<ServiceWorkerRegistration> | undefined = enableSW
  ? new Promise((resolve, reject) => {
      if (!("serviceWorker" in navigator)) {
        reject();
      } else {
        mtLogger.debug("ServiceWorker is supported");
        window.addEventListener("load", () => {
          navigator.serviceWorker
            .register(isProduction ? "/sw.js" : "/dev-sw.js?dev-sw", {
              scope: "/",
              type: isProduction ? "classic" : "module",
            })
            .then(reg => {
              // @ts-ignore
              mtLogger.debug("ServiceWorker registered", reg);
              resolve(reg);
            }, reject);
        });
      }
    })
  : undefined;

(async () => {
  if (!serviceWorkerRegistration) {
    mtLogger.warn(
      "Service Worker has been disabled (we're in dev mode and `VITE_DEBUG_SERVICE_WORKER` is not true)"
    );
    const SWRegistration = await navigator.serviceWorker?.getRegistration();
    if (SWRegistration) {
      mtLogger.warn("But there is a Service Worker running (😱)");
      await SWRegistration.unregister();
      mtLogger.warn("It has been successfully removed, but you should (seriously) reload the page");
    }
  }
})();

const makePageControllerPromise = (): Promise<ServiceWorker> =>
  new Promise(resolve => {
    if (navigator.serviceWorker.controller) {
      resolve(navigator.serviceWorker.controller);
    }
    navigator.serviceWorker.addEventListener("controllerchange", _ => {
      if (navigator.serviceWorker.controller) {
        resolve(navigator.serviceWorker.controller);
      }
    });
  });

const followSWBuildHash = () => {
  makePageControllerPromise()
    .then((controller: ServiceWorker): Promise<any> => {
      const msgChannel = new MessageChannel();
      const rv = new Promise(r => (msgChannel.port1.onmessage = e => r(e.data)));
      controller.postMessage({ type: ASK_SW_BUILD_HASH }, [msgChannel.port2]);
      return rv;
    })
    .then((swBuildHash: string) => {
      mtLogger.debug(`ServiceWorker controlling page is ${swBuildHash}`);
      if (swBuildHash) {
        appConfig["SW_BUILD_HASH"] = swBuildHash;
      }
    });
};

// Check if service worker are supported
export const useFollowServiceWorker = () => {
  // Contrived mechanism to detect and update which service worker is
  // controlling the page. It can change during an update (because we use
  // `skipWaiting`).
  followSWBuildHash();
  navigator.serviceWorker &&
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      mtLogger.debug(`ServiceWorker controller is changing`);
      followSWBuildHash();
    });
};
