import { LegalMode } from "hassibot/services_v2/common/types";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import * as frContractor from "./translations/fr/contractor.json";
import * as frMandatary from "./translations/fr/mandatary.json";
import { i18nNamespaceEnum } from "./types";

export const defaultNamespace = i18nNamespaceEnum.mandatary;
export const frResources = { contractor: frContractor, mandatary: frMandatary };

i18next.use(initReactI18next).init({
  resources: { fr: frResources },
  ns: Object.values(i18nNamespaceEnum),
  defaultNS: defaultNamespace,
  lng: "fr",
});

export const changeLegalModeTranslations = (mode: LegalMode) => {
  switch (mode) {
    case LegalMode.CONTRACTOR:
      i18next.setDefaultNamespace(i18nNamespaceEnum.contractor);
      break;
    case LegalMode.MANDATARY:
      i18next.setDefaultNamespace(i18nNamespaceEnum.mandatary);
      break;
    default:
      throw new Error("Unsupported legal mode");
  }
};

export default i18next;
