import { PropsWithChildren } from "react";
import styled from "styled-components";
import { responsiveHelpers as rh } from "styles/utils";

import { InlineElementStyle } from "styles/atoms/inline-element-style";
import { spaces } from "emoreg/const";

type Props = {
  rowDirection?: boolean;
  className?: string;
  style?: React.CSSProperties;
};
const TitleWithButtonStyle = styled.div`
  & > * {
    margin-bottom: 0;
  }
  ${InlineElementStyle};
  margin-bottom: ${spaces[16]};
  justify-content: space-between;
  ${rh.belowPortraitTablet`
    flex-direction: ${(props: Props) => (props.rowDirection ? "row" : "column")};
  `}
`;

export const TitleWithButton = ({
  children,
  rowDirection,
  className,
  style,
}: PropsWithChildren<Props>) => (
  <TitleWithButtonStyle style={style} className={className} rowDirection={rowDirection}>
    {children}
  </TitleWithButtonStyle>
);
