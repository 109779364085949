import styled from "styled-components";
import { rem } from "polished";

import { radius } from "styles/const";
import { color, spaces } from "emoreg/const";
import { Icon, IconName } from "emoreg/atoms/Icon";

const InfosInsertStyle = styled.div`
  background: ${color.grey.light};
  padding: ${spaces[16]};
  border-radius: ${radius.base};
  display: flex;
  & > div {
    &:first-child {
      padding-right: ${spaces[8]};
    }
    &:last-child {
      flex-grow: 1;
    }
  }

  p {
    margin-bottom: 0;
  }
`;

const InfosInsertText = styled.div`
  font-size: ${rem(14)};
  line-height: 150%;
  margin: 0;
  color: ${color.grey.dark};
`;

type Props = {
  children: any;
  icon?: IconName;
  style?: React.CSSProperties;
};

const InfosInsert = ({ children, icon, style }: Props) => (
  <InfosInsertStyle style={style}>
    {icon && (
      <div>
        <Icon name={icon} size={14} color={color.grey.dark} />
      </div>
    )}
    <div>
      <InfosInsertText>{children}</InfosInsertText>
    </div>
  </InfosInsertStyle>
);

export default InfosInsert;
