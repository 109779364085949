import {
  ApiResponse,
  ApiSuccess,
  ClientError,
  EmptySuccess,
  Failure,
  GlobalError,
  Pager,
  UnrecoverableHttpError,
  FetchError,
  TimeoutError,
  ResponseHook,
  FieldErrorsWithExtra,
} from "../types";

const success = <T>(request: Request, response: Response, value: T): ApiSuccess<T> => {
  const pagerHeader = response.headers.get("oh-pager"),
    aggregates = response.headers.get("oh-pager-aggregates");
  const pager = pagerHeader
    ? Pager.fromQueryString(pagerHeader, aggregates ? JSON.parse(aggregates) : null)
    : null;
  return {
    statusCode: response.status,
    headers: response.headers,
    kind: "SUCCESS",
    value,
    ...(pager ? { pager } : {}),
    request,
  };
};

const empty = (request: Request, response: Response): EmptySuccess =>
  success(request, response, undefined);

const clientError = (
  request: Request,
  response: Response,
  error: FieldErrorsWithExtra
): ClientError => ({
  kind: "CLIENT_ERROR",
  statusCode: response.status,
  headers: response.headers,
  /** @deprecated Use `errorWithExtra` instead.*/
  error: Object.entries(error).reduce(
    (acc, [key, errorsWithExtra]) => ({
      ...acc,
      [key]: errorsWithExtra.map((errorWithExtra) => errorWithExtra.type),
    }),
    {}
  ),
  errorWithExtra: error,
  request,
});

const globalError = (
  request: Request,
  response: Response,
  error: string,
  extra?: Record<string, any>
): GlobalError => ({
  kind: "GLOBAL_ERROR",
  statusCode: response.status,
  headers: response.headers,
  error,
  extra,
  request,
});

export const timeoutError = (request: Request, callTimeoutMs: number): TimeoutError => ({
  kind: "TIMEOUT_ERROR",
  error: "TIMEOUT_ERROR",
  request,
  timeoutMs: callTimeoutMs,
});

const unrecoverableHttpError = (
  request: Request,
  response: Response,
  innerJson: {}
): UnrecoverableHttpError => ({
  kind: "UNRECOVERABLE_HTTP_ERROR",
  statusCode: response.status,
  headers: response.headers,
  error: innerJson,
  request,
});

const fetchError = (request: Request, error: Error): FetchError => ({
  kind: "FETCH_ERROR",
  error,
  request,
});

const httpFailure = (request: Request, response: Response, innerJson: any): Failure => {
  if (innerJson && "global" in innerJson) {
    return globalError(request, response, innerJson.global, innerJson.extra);
  } else if (innerJson && "fields" in innerJson) {
    return clientError(request, response, innerJson.fields);
  } else {
    return unrecoverableHttpError(request, response, innerJson);
  }
};

const fetchFailure = (request: Request, error: Error): Failure => {
  return fetchError(request, error || new Error("UNKNOWN_FETCH_ERROR"));
};

export const handleResponsePromise = <T>(
  request: Request,
  responsePromise: Promise<Response>,
  preProcessingResponseHooks?: ResponseHook[]
): Promise<ApiResponse<T>> => {
  return responsePromise.then(
    async (response: Response) => {
      for (const hook of preProcessingResponseHooks || []) {
        await Promise.resolve(hook(response));
      }

      if (response.status === 204) {
        return Promise.resolve(empty(request, response));
      }

      let responseJson;
      try {
        /**
         * The `response.json()` call can fail for (at least) two
         * reasons:
         *    - The response body is not JSON
         *    - A network failure happened during the reading of the
         *      response
         *
         * In our case the response body, coming from the API, must be
         * JSON. If need be we could ensure that
         * `response.headers["Content-Type"] === application/json`
         * before calling `response.json()` (and throw a more precise
         * error if it isn't).
         *
         * Hence the second case: we had a network failure.
         *
         * Quick reminder to understand why it can happen: the
         * `fetch()` call itself stops reading from the network right
         * after the headers, and in particular right before the body,
         * the subsequent network interactions (to read the body) are
         * done in `response.json()`, hence the possibility for more
         * network failures...
         */
        responseJson = await response.json();
      } catch (error) {
        if (error instanceof Error) {
          return fetchFailure(request, error);
        } else if (typeof error === "string") {
          return fetchFailure(request, new Error(error));
        } else if (typeof error === "boolean" || typeof error === "number") {
          console.warn(
            "Be careful error is handle by a backup function, its type is strange. maybe you should consider to make a patch"
          );
          return fetchFailure(request, new Error(`${error}`));
        } else {
          return fetchFailure(
            request,
            new TypeError(
              `Error thrown should not follow this type : ${typeof error}. Prefer pass throught Error constructor `
            )
          );
        }
      }

      if (response.status < 400) {
        return success(request, response, responseJson as T);
      }

      return httpFailure(request, response, responseJson);
    },
    (error: Error) => fetchFailure(request, error)
  );
};
