import styled from "styled-components";

import { color, spaces } from "emoreg/const";
import { responsiveHelpers as rh } from "styles/utils";

/**
 * @deprecated use a more specific component instead. You can use "Padder“ "Grid" to acheive the same result.
 */
export const SectionGeneralStyle = styled.div`
  display: flex;
  padding: ${spaces[24]} ${spaces[32]} ${spaces[32]} ${spaces[32]};
  margin: 0 -${spaces[16]};
  & > * {
    flex-grow: 1;
    padding: 0 ${spaces[16]};
  }
  ${rh.belowPortraitTablet`
   padding: ${spaces[32]} 0;
  `}
  &:not(:last-child) {
    border-bottom: 0.5px solid ${color.grey.base};
  }
`;
