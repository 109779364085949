export const ASK_SW_BUILD_HASH = "ASK_SW_BUILD_HASH";
export const ASK_BOOM = "ASK_BOOM";

const OUIHELP_BLUE = "#002952";
const OUIHELP_LIGHTER_BLUE = "#4d6986";

const OUIHELP_GREEN = "#3ed096";
const OUIHELP_LIGHTER_GREEN = "#b2ecd5";

enum LogLevel {
  error = "error",
  warn = "warn",
  log = "log",
  debug = "debug",
  info = "info",
}

const levelToMtColor = {
  [LogLevel.error]: "red",
  [LogLevel.warn]: "orange",
  [LogLevel.log]: OUIHELP_BLUE,
  [LogLevel.debug]: OUIHELP_LIGHTER_BLUE,
  [LogLevel.info]: OUIHELP_LIGHTER_BLUE,
};

const levelToSwColor = {
  [LogLevel.error]: "red",
  [LogLevel.warn]: "orange",
  [LogLevel.log]: OUIHELP_GREEN,
  [LogLevel.debug]: OUIHELP_LIGHTER_GREEN,
  [LogLevel.info]: OUIHELP_LIGHTER_GREEN,
};

const makeLogWithPrefix = (logLevel: LogLevel, color: string, prefix: string) => {
  const logPrefix = [
    `%c${prefix}`,
    `background: ${color}; color: white; padding: 2px 0.5em; font-weight: bold; ` +
      `border-radius: 0.5em;`,
  ];
  return (...logArgs: string[]): void => console[logLevel](...logPrefix, ...logArgs);
};

type MtLogger = { [index in LogLevel]: { (...logArgs: string[]): void } };

const mtLogger = {} as MtLogger;
const serviceWorkerLogger: { [index: string]: { (...logArgs: string[]): void } } = {};
const webWorkerLogger: { [index: string]: { (...logArgs: string[]): void } } = {};

Object.entries(levelToMtColor).forEach(
  ([logLevel, color]: [string, string]) =>
    (mtLogger[logLevel] = makeLogWithPrefix(logLevel as LogLevel, color, "HB-MainThread"))
);
Object.entries(levelToSwColor).forEach(
  ([logLevel, color]: [string, string]) =>
    (serviceWorkerLogger[logLevel] = makeLogWithPrefix(
      logLevel as LogLevel,
      color,
      "HB-ServiceWorker"
    ))
);
Object.entries(levelToSwColor).forEach(
  ([logLevel, color]: [string, string]) =>
    (webWorkerLogger[logLevel] = makeLogWithPrefix(logLevel as LogLevel, color, "HB-Webworker"))
);

export { mtLogger, serviceWorkerLogger, webWorkerLogger };

// This must be in `hassibot/util` as it's not shared
export const urlB64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
