import styled from "styled-components";

import { spaces } from "emoreg/const";

import { Icon } from "emoreg/atoms/Icon";

export class ChosenSize {
  xs = 8;
  sm = 12;
  base = 14;
  md = 16;
  lg = 18;
}
const sizesOfIcon = new ChosenSize();
type Props = {
  name: string;
  size?: keyof ChosenSize;
  label?: any;
  bold?: boolean;
  color?: string;
};

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CustomLabel = styled.p`
  font-size: ${props => (props.size ? props.size : 14)}px;
  color: inherit;
  position: relative;
`;

const IconLabel = styled(Icon)`
  margin-right: ${spaces[8]};
`;

const IconLabelled = ({ name, label, size, color }: Props) => (
  <Wrapper>
    <IconLabel name={name} color={color} size={size ? sizesOfIcon[size] : null} />
    <CustomLabel size={size ? sizesOfIcon[size] : null}>{label}</CustomLabel>
  </Wrapper>
);

export default IconLabelled;
