import styled from "styled-components";
import { prop } from "styled-tools";
import { DefaultPicture } from "hassibot/component/assets";

const PhotoOuter = styled.span`
  display: block;
  flex-shrink: 0;
  border-radius: 70px;
  background-image: url(${prop("url")});
  background-size: cover;

  overflow: hidden;
  ${props =>
    props.size
      ? `
    width: ${props.size};
    height: ${props.size};
  `
      : null};
`;

type PhotoProps = {
  url: string | null;
  size: string;
  style?: React.CSSProperties;
};
export const Photo = ({ url, size, style }: PhotoProps) => (
  <PhotoOuter size={size} url={url || DefaultPicture} style={style} />
);
