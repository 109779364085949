import { Duration } from "luxon";

import { LegalMode } from "hassibot/services_v2/common/types";
import {
  CacheEvictionStrategy,
  ALL_ENTITY_STORES_NAMES,
  makeClassicTimeBasedCacheEvictionStrategy,
  appVersion,
} from "./simple-object-store";

export const ALL_LEGAL_MODES = "all";

type CacheConfigurationContent = {
  evictionStrategy: CacheEvictionStrategy;
  mode: LegalMode | typeof ALL_LEGAL_MODES;
};

export const cacheConfiguration: Record<ALL_ENTITY_STORES_NAMES, CacheConfigurationContent> = {
  [ALL_ENTITY_STORES_NAMES.CaregiversFullEntities]: {
    evictionStrategy: makeClassicTimeBasedCacheEvictionStrategy(
      Duration.fromObject({ seconds: 10 })
    ),
    mode: ALL_LEGAL_MODES,
  },
  [ALL_ENTITY_STORES_NAMES.ProclientsFullEntities]: {
    evictionStrategy: makeClassicTimeBasedCacheEvictionStrategy(
      Duration.fromObject({ seconds: 10 })
    ),
    mode: ALL_LEGAL_MODES,
  },
  [ALL_ENTITY_STORES_NAMES.StructureEntities]: {
    evictionStrategy: appVersion,
    mode: ALL_LEGAL_MODES,
  },
  [ALL_ENTITY_STORES_NAMES.PrescribersFullEntities]: {
    evictionStrategy: makeClassicTimeBasedCacheEvictionStrategy(
      Duration.fromObject({ seconds: 10 })
    ),
    mode: ALL_LEGAL_MODES,
  },
  [ALL_ENTITY_STORES_NAMES.StaticEntries]: {
    evictionStrategy: appVersion,
    mode: ALL_LEGAL_MODES,
  },
};
