import DOMPurify from "dompurify";
import { color, shadow, spaces } from "emoreg/const";
import { uuidv4 } from "hassibot/util/uuid";
import { rem, triangle } from "polished";
import { SideKeyword } from "polished/lib/types/sideKeyword";
import { TriangleConfiguration } from "polished/lib/types/triangleConfiguration";
import React, { CSSProperties, PropsWithChildren, useCallback, useState } from "react";
import styled, { css } from "styled-components";

const CustomContainer = styled.span`
  margin-left: 5px;
  position: relative;
  display: flex;
  align-items: center;
`;
const computeTooltipPosition = (pos: TooltipPosition): string => {
  switch (pos) {
    case "bottom":
      return css`
        top: calc(100% + 6px);
        left: 50%;
        transform: translateX(-50%);
      `;
    case "top":
      return css`
        bottom: calc(100% + 6px);
        left: 50%;
        transform: translateX(-50%);
      `;
    case "left":
      return css`
        top: calc(50%);
        right: calc(100% + 6px);
        transform: translateY(-50%);
      `;
    case "right":
      return css`
        top: calc(50%);
        left: calc(100% + 6px);
        transform: translateY(-50%);
      `;
  }
};
const computeTrianglePosition = (pos: TooltipPosition): string => {
  switch (pos) {
    case "bottom":
      return css`
        bottom: calc(100% - 1px);
        left: 50%;
        transform: translate(-50%, 40%);
      `;
    case "top":
      return css`
        top: calc(100% - 10px);
        left: 50%;
        transform: translate(-50%, 40%);
      `;
    case "right":
      return css`
        top: 50%;
        right: calc(100% - 10px);
        transform: translate(-40%, -50%);
      `;
    case "left":
      return css`
        top: 50%;
        left: 100%;
        transform: translate(-40%, -50%);
      `;
  }
};
const triangleDefaultStyle = {
  foregroundColor: "#fff",
};
const computeTrianglePointingDirection = (
  pos: TooltipPosition
): { pointingDirection: SideKeyword; height: string; width: string } => {
  switch (pos) {
    case "bottom":
      return { pointingDirection: "top", width: rem(25), height: rem(14) };
    case "top":
      return { pointingDirection: "bottom", width: rem(25), height: rem(14) };
    case "left":
      return { pointingDirection: "right", width: rem(14), height: rem(25) };
    case "right":
      return { pointingDirection: "left", width: rem(14), height: rem(25) };
  }
};
const computeTriangle = (pos: TooltipPosition): TriangleConfiguration => ({
  ...triangleDefaultStyle,
  ...computeTrianglePointingDirection(pos),
});
const TooltipStyle = styled.div`
  position: absolute;
  filter: drop-shadow(${shadow.base});
  ${props => computeTooltipPosition(props.tooltipPosition)}
  padding: ${spaces[8]} ${spaces[12]};
  background: #fff;
  color: white;
  border-radius: 4px;
  z-index: 1;
  ${({ width }: { width: string }) =>
    width
      ? css`
          width: ${rem(width)};
          white-space: normal;
        `
      : css`
          white-space: nowrap;
        `}
  ${({ show }: { show: boolean }) =>
    show
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
  &::after {
    content: "";
    position: absolute;
    ${props => computeTrianglePosition(props.tooltipPosition)}
    border-radius: ${spaces[4]};
    ${props => triangle(computeTriangle(props.tooltipPosition))};
  }
`;
const Content = styled.div`
  font-size: ${rem(12)};
  line-height: 1.4;
  color: ${color.grey.darker};
`;

export type TooltipPosition = "bottom" | "top" | "left" | "right";
type TooltipProps = {
  text: string;
  tooltipPosition: TooltipPosition;
  width?: number;
  style?: React.CSSProperties;
};

export const Tooltip = ({
  text,
  children,
  width,
  tooltipPosition,
  style,
}: PropsWithChildren<TooltipProps>) => {
  const [isTooltipShown, setTooltipShow] = useState(false);
  const showTooltip = useCallback(() => setTooltipShow(true), []);
  const hideTooltip = useCallback(() => setTooltipShow(false), []);

  const childs = React.Children.map(children, c =>
    React.isValidElement(c)
      ? React.cloneElement(c as React.ReactElement<{ style: CSSProperties }>, {
          style: {
            ...(c.props.style ? { ...c.props.style } : {}),
            cursor: "pointer",
          },
          key: uuidv4(),
        })
      : c
  );

  return (
    <CustomContainer onMouseEnter={showTooltip} onMouseLeave={hideTooltip} style={style}>
      {childs}
      <TooltipStyle tooltipPosition={tooltipPosition} show={isTooltipShown} width={width}>
        <Content dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />
      </TooltipStyle>
    </CustomContainer>
  );
};
