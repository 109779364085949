import { Router } from "./types";

export const prependUri =
  (prefix: string) =>
  <T>(router: Router<T>): Router<T> =>
    Object.keys(router).reduce((acc, key) => {
      const route = router[key];
      return {
        ...acc,
        [key]: (...args: string[]): string => prefix + route.apply(null, args),
      };
    }, {} as Router<T>);

export const toQueryString = (obj: {}): string =>
  Object.keys(obj)
    .reduce((acc, key) => {
      acc.set(key, obj[key]);
      return acc;
    }, new URLSearchParams())
    .toString();
