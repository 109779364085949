import { get, set } from "hassibot/local-storage";
import { useSessionCollaboratorContext } from "inside-components";
import React, { useContext, useLayoutEffect, useState } from "react";
import { changeLegalModeTranslations } from "./i18next";
import { hassibotQueryClient } from "./react-query/query-client";
import { client } from "./services_v2";
import { indexedDBCache } from "./services_v2/common/cache";
import { LegalEntity, LegalMode } from "./services_v2/common/types";

const CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY = "collaborator-current-legal-entity-uuid";

interface CurrentLegalEntityContextType {
  currentLegalEntity: LegalEntity;
  setCurrentLegalEntity: (newLegalEntity: LegalEntity) => void;
}

const CurrentLegalEntityContext = React.createContext<CurrentLegalEntityContextType | undefined>(
  undefined
);

export const CurrentLegalEntityProvider = ({ children }) => {
  const { myAccessibleLegalEntities } = useSessionCollaboratorContext();

  const getDefaultLegalEntity = (): LegalEntity => {
    if (myAccessibleLegalEntities.length === 0) {
      throw new Error("No legal entity found for the current user");
    }

    // Get initial value from local storage or arbitrarily use the first value from the given list
    const legalEntityUuidFromLocalStorage = get(CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY);
    const currentLegalEntity =
      myAccessibleLegalEntities.find(
        legalEntity => legalEntityUuidFromLocalStorage === legalEntity.uuid
      ) ?? myAccessibleLegalEntities[0];

    return currentLegalEntity;
  };

  const [legalEntity, setLegalEntity] = useState<LegalEntity>(getDefaultLegalEntity);

  useLayoutEffect(() => {
    client.setLegalEntityUuid(legalEntity.uuid);
    set(CURRENT_LEGAL_ENTITY_LOCAL_STORAGE_KEY, legalEntity.uuid);
    changeLegalModeTranslations(legalEntity.mode);
    indexedDBCache.initLegalEntityDatabase(legalEntity);
    hassibotQueryClient.resetQueries();
  }, [legalEntity]);

  return (
    <CurrentLegalEntityContext.Provider
      value={{ currentLegalEntity: legalEntity, setCurrentLegalEntity: setLegalEntity }}
    >
      {children}
    </CurrentLegalEntityContext.Provider>
  );
};

export const useLegalMode = () => {
  const context = useContext(CurrentLegalEntityContext);

  if (!context) {
    throw new Error(
      "The application context is missing, add 'CurrentLegalEntityProvider' at the top of component hierarchy"
    );
  }

  const { currentLegalEntity, setCurrentLegalEntity } = context;

  const isMandatary = currentLegalEntity.mode === LegalMode.MANDATARY;
  const isContractor = currentLegalEntity.mode === LegalMode.CONTRACTOR;

  return {
    mode: currentLegalEntity.mode,
    isMandatary,
    isContractor,
    currentLegalEntity,
    setCurrentLegalEntity,
  };
};
