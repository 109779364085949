import styled, { keyframes } from "styled-components";

const blink = keyframes`
  50% {color: transparent;}
`;

const Dot = styled.span`
  font-size: 0.8em;
  animation: 1s ${blink} infinite;
  &:nth-child(1) {
    animation-delay: 0ms;
  }
  &:nth-child(2) {
    animation-delay: 250ms;
  }
  &:nth-child(3) {
    animation-delay: 500ms;
  }
`;

export const InlineLoader = () => (
  <>
    {Array(3)
      .fill(0)
      .map((_, i) => (
        <Dot key={i}>●</Dot>
      ))}
  </>
);
