import { rem } from "polished";
import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { FontStyle, FontWeight } from "styles/const";

import { color, font } from "emoreg/const";
import { lhCrop } from "emoreg/utils/lh-crop";
import { switchProp } from "emoreg/utils/switch-prop";
import { ifProp } from "styled-tools";

type TypographySizes = "small" | "medium" | "large";
type TypographyProps = {
  className?: string;
  size?: TypographySizes;
  fontWeight?: FontWeight;
  color?: string;
  fontStyle?: FontStyle;
  ellipsis?: boolean;
};

const TypographyStyle = styled.p`
  ${lhCrop(1.5)};
  line-height: 1.5;
  font-weight: ${props => props.$fontWeight || font.weight.normal};
  color: ${props => props.$color || color.grey.darker};
  font-style: ${props => props.$fontStyle || "normal"};
  ${switchProp("$size", {
    large: css`
      font-size: ${rem(16)};
    `,
    medium: css`
      font-size: ${rem(14)};
    `,
    small: css`
      font-size: ${rem(12)};
    `,
  })}
  ${ifProp("$ellipsis", {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  })}
`;

export const Typography: React.FC<PropsWithChildren<TypographyProps>> = props => (
  <TypographyStyle
    $size={props.size}
    $color={props.color}
    className={props.className}
    $fontWeight={props.fontWeight}
    $fontStyle={props.fontStyle}
    $ellipsis={props.ellipsis}
  >
    {props.children}
  </TypographyStyle>
);
