export const BROADCAST_CHANNEL_LOGIN = "LOGIN_CHANNEL";
export const BROADCAST_CHANNEL_DOCUMENTS = "DOCUMENTS_CHANNEL";
export const BROADCAST_CHANNEL_LOGIN_SIGNIN = "BROADCAST_CHANNEL_LOGIN_SIGNIN";
export const BROADCAST_CHANNEL_LOGIN_SIGNOUT = "BROADCAST_CHANNEL_LOGIN_SIGNOUT";

export const emitOnBroadcastChannel = (channel: string, message) => {
  const bc = new BroadcastChannel(channel);
  bc.postMessage(message);
  bc.close();
};

export const listenOnBroadcastChannel = (channel: string, callback: (any) => void) => {
  const bc = new BroadcastChannel(channel);
  bc.onmessage = callback;
  return () => bc.close();
};
