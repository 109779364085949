import { spaces } from "emoreg/const";
import styled, { css } from "styled-components";
import { switchProp, ifProp, ifNotProp } from "styled-tools";

import { responsiveHelpers as rh } from "styles/utils";

interface Props {
  columns: number;
}

const FieldWrapper = styled.div<Props>`
  margin-bottom: ${spaces[32]};

  ${rh.belowPortraitTablet`
    & > div {
      margin-bottom: ${spaces[32]};
    }
  `};

  ${rh.forPortraitTabletUp`
    ${ifNotProp(
      { columns: 1 },
      css`
        display: flex;
        justify-content: space-between;
      `
    )}
    ${switchProp("columns", {
      1: css`
        max-width: 100%;
      `,
      2: css`
        margin-left: -${spaces[8]};
        margin-right: -${spaces[8]};
        & > div {
          padding: 0 ${spaces[8]};
          width: calc(100% / 2);
        }
      `,
      3: css`
        margin: 0 -${spaces[8]};
        & > div {
          padding: 0 ${spaces[8]};
          width: calc(100% / 3);
        }
      `,
    })}
  `};

  ${ifProp(
    { size: "auto" },
    css`
      & > * {
        width: auto;
      }
    `
  )}
`;

FieldWrapper.defaultProps = {
  columns: 1,
};
/**
 * @deprecated please use appropriate layout in "/layout" folder of emoreg
 */
export default FieldWrapper;
